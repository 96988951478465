<template>
  <div class="cocoonx-dashboard">
    <!-- <Header /> -->
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <AlertUpgrade v-if="role == 'FREE_MEMBER'" />


          <div class="row pt-4">
            <div class="col-12 col-xl-6">
               <NewsSlider />
            </div>
            <div class="col-12 col-xl-6">
              <InnovationHub />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ArticleSlider />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <EventSlider />
            </div>
          </div>

          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import Header from "../../components/Dashboard/Header";
import AlertUpgrade from "../../components/Dashboard/AlertUpgrade";
import NewsSlider from "../../components/Dashboard/NewsSlider";
import ArticleSlider from "../../components/Dashboard/ArticleSlider";
import EventSlider from "../../components/Dashboard/EventSlider";
import InnovationHub from "../../components/Dashboard/InnovationHub";
import axios from "axios";
import { mapFields } from "vuex-map-fields";

// import jQuery from "jquery";
// import slick from "slick-carousel";
export default {
  name: "Dashboard",

  components: {
    Sidebar,
    Header,
    AlertUpgrade,
    NewsSlider,
    ArticleSlider,
    EventSlider,
    InnovationHub,
  },
  data() {
    return {
      component: "Feed",
      menuState: "show",
      visible: true,
      active: "",
      name: "",
    };
  },
  mounted() {
    this.$store.commit("setPageType", 'dashboard')  
  },
  computed: {
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    ...mapFields(["profile.role"]),
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>

a.disabled {
  pointer-events: none;
}

.slick-list {
  overflow: hidden;
}

.slick-initialized {
  overflow: hidden;
}
</style>