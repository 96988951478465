<template>
  <div id="app" :class="currentRoute + ' ' + route">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { mapState} from "vuex";
export default {
  name: "App",
  data() {
    return {
      route: this.$route.meta.type,
    };
  },
  components: {
    Navbar,
    Footer,
  },
  watch: {
    $route(to, from) {
      ;
      let token = sessionStorage.getItem("cocoonx_access_token");
      if (!token) {
        sessionStorage.removeItem("cocoonx_access_token")
        this.$store.dispatch("logout");
        // this.$store.commit("setPageType", this.$route.meta.type)
      } else {
        // this.$store.commit("setPageType", 'dashboard')
      }
    },
  },
  mounted() {
    this.reset();
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    ...mapState(["profile"]),
  },
  methods: {
    reset() {
      let token = sessionStorage.getItem("cocoonx_access_token");
      if (!token) {
        sessionStorage.removeItem("cocoonx_access_token")
        this.$store.dispatch("logout");
      }
    },
  },
};
</script>
<style lang="scss">
@import "assets/scss/main.scss";
</style>
