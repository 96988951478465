<template>
  <div>
    <div class="section pb-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center">
            <h1>Join our Community</h1>
            <p>
              Enjoy discounts to events and meet up with inspiring entrepreneurs
            </p>
            <img
              src="@/assets/img/join-us.png"
              alt="community"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section smaller-padding bg-primary-color dark">
      <div class="overlap-block">
        <div class="container">
          <div class="row justify-content-center gx-4">
            <div class="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
              <div class="card text-center community free">
                <div class="card-header" style="height: 90px">
                  <h4 class="bold mb-0">Free</h4>
                  <!-- <p>forever</p> -->
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <p>Benefit</p>
                    </li>
                    <li>
                      <p>Benefit</p>
                    </li>
                    <li>
                      <p>Benefit</p>
                    </li>
                    <li class="not-available">
                      <p>Benefit</p>
                    </li>
                  </ul>
                </div>
                <div
                  class="card-footer d-flex align-items-center justify-content-center"
                  style="height: 100px"
                >
                  <a href="#" class="transition-3d-hover">
                    <h6 class="bold mb-0">
                      <!-- <i class="fas fa-bicycle"></i>  -->
                      Start with free
                    </h6>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card text-center community pro">
                <div class="card-header" style="height: 90px">
                  <h4 class="bold mb-0">PRO</h4>
                  <p>RMxx per year</p>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <p>Benefit</p>
                    </li>
                    <li>
                      <p>Benefit</p>
                    </li>
                    <li>
                      <p>Benefit</p>
                    </li>
                    <li>
                      <p>Benefit</p>
                    </li>
                  </ul>
                </div>
                <div
                  class="card-footer d-flex align-items-center justify-content-center"
                  style="height: 100px"
                >
                  <a href="#" class="transition-3d-hover">
                    <h6 class="bold mb-0">
                      <!-- <i class="fas fa-fighter-jet"></i>  -->
                      Start with PRO
                    </h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CTA />
  </div>
</template>

<script>
import CTA from "../components/CTA";
export default {
  name: "JoinCommunity",
  components: {
    CTA,
  },
};
</script>