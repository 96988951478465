<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div
            :class="
              'row justify-content-' +
              [assessors && !assessors.length ? 'between' : 'end']
            "
          >
            <div class="col-6" v-if="assessors && !assessors.length">
              <p class="f-heading">No assessors available yet..</p>
            </div>
            <div class="col-4 text-end">
              <button
                class="btn btn-primary transition-3d-hover ms-auto"
                data-bs-toggle="modal"
                data-bs-target="#addAssessorModal"
              >
                Add Assessor <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              </button>
            </div>
          </div>

          <div class="row" v-if="assessors && assessors.length">
            <div class="col-12">
              <div class="table-responsive mt-4">
                <table class="table table-hover table-borderless white-table-list">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="d-flex">Name</div>
                      </th>
                      <th scope="col" class="text-center only-mobile">
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <span>Email</span>
                        </div>
                      </th>
                      <th scope="col" class="text-center only-mobile">
                        <div class="d-flex align-items-center">
                          <span>Mobile number</span>
                        </div>
                      </th>
                      <th scope="col" class="text-center only-desktop">
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <span>Contact</span>
                        </div>
                      </th>
                      <th scope="col" class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="assessor in assessors" :key="assessor.id">
                      <th>{{ assessor.name }}</th>
                      <td class="text-center only-mobile">
                        {{ assessor.email }}
                      </td>

                      <td class="text-start only-mobile">
                        {{ assessor.mobile_no }}
                      </td>
                      <td class="text-start only-desktop">
                        <div class="action d-flex justify-content-center">
                          <a :href="'mailto:' + assessor.email" class="fs-6"
                            ><i class="bi bi-envelope"></i
                          ></a>
                          <a :href="'tel:' + assessor.mobile_no" class="fs-6"
                            ><i class="bi bi-phone-vibrate ms-4"></i
                          ></a>
                        </div>
                      </td>
                      <td>
                        <div class="action d-flex justify-content-center">
                          <button
                            class="btn btn-link text-primary text-center py-0"
                            @click="
                              assessorID = assessor.id;
                              assessorName = assessor.name;
                              viewAssessor(assessor.id, assessor.name);
                            "
                          >
                            <i class="bi bi-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Modal: create assessor -->
          <div
            class="modal fade viewDetails"
            id="addAssessorModal"
            tabindex="-1"
            aria-labelledby="addAssessorModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title mx-auto" id="addAssessorModalLabel">
                    Add a new assessor
                  </h5>
                </div>
                <div class="modal-body">
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Name</p>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" v-model="name" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Email</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="email"
                        class="form-control"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Mobile number</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="mobile"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="createAssessor()"
                  >
                    Create assessor
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal: delete assessor -->
          <!-- Modal -->
          <div
            class="modal fade viewDetails"
            id="deleteAssessorModal"
            tabindex="-1"
            aria-labelledby="deleteAssessorModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title text-capitalize"
                    id="deleteAssessorModalLabel"
                  >
                    {{ assessorStatus }} assessor
                  </h5>
                </div>
                <div class="modal-body">
                  <p>
                    Are you sure you want to <b>{{ assessorStatus }} </b> this
                    assessor?
                  </p>
                  <p class="f-heading">{{ assessorName }}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    :class="
                      'btn text-capitalize ' +
                      [
                        assessorStatus == 'delete'
                          ? 'btn-danger'
                          : 'btn-primary',
                      ]
                    "
                    @click="deleteAssessor(assessorID)"
                  >
                    {{ assessorStatus }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Toast -->
          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal, Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  name: "ViewAssesors",
  components: {
    Sidebar,
  },
  data() {
    return {
      assessorID: "",
      assessorName: "",
      assessorStatus: "",
      name: "",
      mobile: "",
      email: "",
      toastMsg: "",
      loading: false
    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMM YYYY, h:mm:ss a");
    },
  },
  mounted() {
    this.$store.dispatch("loadAssessorList");
    this.addAssessor = new Modal(document.getElementById("addAssessorModal"));
    this.deleteAssessorModal = new Modal(
      document.getElementById("deleteAssessorModal")
    );
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
  },
  computed: {
    /** Call state companies */
    ...mapState(["assessors"]),

    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
  },
  methods: {
    async createAssessor() {
      this.loading = true
      const response = await axios.post(
        "admin/assessor/create",
        {
          name: this.name,
          mobile_no: this.mobile,
          email: this.email,
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.$store.dispatch("loadAssessorList");
      this.addAssessor.hide();
      this.toastMsg = "Assessor created successfully";
      this.successToast.show();
      this.loading = false
    },
    viewAssessor(id, name) {
      this.$router.push("/assessor/" + id + "/" + name + "");
    },
  },
};
</script>