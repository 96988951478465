<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
                    <div class="row align-items-stretch">
            <div class="col-12 col-md-6 mb-4">
              <div class="card dashboard-cards">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">New assessment to score</h5>
                    <p class="mb-0 fs-4">
                      <i class="bi bi-hourglass-split"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <div class="card dashboard-cards">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="mb-0">Draft assessments</h5>
                    <p class="mb-0 fs-4">
                      <i class="bi bi-hourglass-split"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <div class="card dashboard-cards admin-dashboard">
                <div class="card-header">
                  <h5 class="mb-0">Continue scoring</h5>
                </div>
                <div class="card-body">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col-8">Company name</th>
                        <th scope="col-4 text-end">Program name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Company name 1</td>
                        <td>Enrolled program</td>
                      </tr>
                      <tr>
                        <td>Company name 2</td>
                        <td>Enrolled program</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
export default {
  name: "Assessor",
  components: {
    Sidebar,
  },
  mounted() {
    this.$store.commit("setPageType", 'dashboard')
  }
};
</script>