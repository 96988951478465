<template>
  <div
    class="header"
    style="background-image: url(~@/assets/img/abstract-shapes-20.svg)"
  >
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-12 col-md-8 text-end">
          <h3>Welcome, {{ name }}</h3>
          <p>{{route}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "Header",
  data() {
    return {
      route : this.$route.name
    }
  },
  computed: {
    ...mapFields(["profile.name", "profile.role"]),
  },
};
</script>