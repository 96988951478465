import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {
  getField,
  updateField
} from 'vuex-map-fields';
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://dev.cocoonx.com/api/'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('cocoonx_access_token')


export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.getJSON(key),
      setState: (key, state) => Cookies.set(key, state, {
        expires: 3,
        secure: true
      })
    })
  ],
  state: {
    accessToken: '',
    currentCompany: '',
    pageType: 'website',
    profile: [],
    role: '',
    companies: [],
    programs: [],
    assessors: [],
    enrollments:[],
    members: [],
    memberListPage: '',
    formItems:[],
    assessments:[]
  },
  mutations: {
    setPageType(state, payload){
      state.pageType = payload
    },
    setProfile(state, payload){
      state.profile = payload
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setMembers(state, payload) {
      state.members = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setPrograms(state, payload) {
      state.programs = payload;
    },
    updateField,

    SET_Profile(state, profile) {
      state.profile = profile
    },
    SET_Role(state, role) {
      state.role = role
    },

    SET_Programs(state, programs) {
      state.programs = programs
    },

    SET_Companies(state, companies) {
      state.companies = companies
    },

    SET_Members(state, members) {
      state.members = members
    },

    SET_Programs(state, programs) {
      state.programs = programs
    },
    SET_Assessors(state, assessors) {
      state.assessors = assessors
    },
    SET_Form(state, formItems) {
      state.formItems = formItems
    },
    SET_Page(state, memberListPage) {
      state.memberListPage = memberListPage
    },
    SET_Assessments(state, assessments) {
      state.assessments = assessments
    },
    SET_Enrollments(state, enrollments) {
      state.enrollments = enrollments
    },

    SET_Logout(state){
      state.profile = []
      state.companies = []
      state.programs = []
      state.members = []
      state.accessToken = ''
      sessionStorage.removeItem('cocoonx_access_token')
      state.pageType = 'website'
    },

    CLEAR_Members(state){
      state.members = []
    }
  },
  actions: {

    async logout({
      commit,
      state
    }) {
      await axios.get('auth/logout', {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        })
        .then(commit('SET_Logout'))
    },

    loadProfile({
      commit,
      state
    }) {
      axios.get('user/profile', {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        })
        .then(response => response.data.profile)
        .then(profile => {
          commit('SET_Profile', profile)
          commit('SET_Role', profile.role)
        })
    },

    loadCompanies({
      commit,
      state
    }) {
      axios.get('company/list', {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        })
        .then(response => response.data.companyList)
        .then(companies => {
          // console.log(programs);
          commit('SET_Companies', companies)
        })
    },

    loadPrograms({
      commit,
      state
    }) {
      axios.get('program/list', {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        })
        .then(response => response.data.programList)
        .then(programs => {
          // console.log(programs);
          commit('SET_Programs', programs)
        })
    },

    loadEnrollments({commit, state}) {
      axios.get('enrollment/list', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data.enrollmentList)
      .then(programs => {
        // console.log(programs);
        commit('SET_Enrollments', programs)
      })
    },

    loadMemberList({commit, state}) {
      axios.get('admin/member/list', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data)
      .then(member => {
        commit('SET_Members', member.userList)
        commit('SET_Page', member.totalPage)
      })
    },

    loadCompanyList({commit, state}) {
      axios.get('admin/company/list', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data)
      .then(company => {
        commit('SET_Companies', company.companyList)
        commit('SET_Page', company.totalPage)
      })
    },

    loadProgramList({commit, state}) {
      axios.get('program/list', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data)
      .then(company => {
        commit('SET_Programs', company.programList)
      })
    },
    loadAssessorList({commit, state}) {
      axios.get('admin/assessor/list', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data.userList)
      .then(assessor => {
        commit('SET_Assessors', assessor)
      })
    },
    loadFormList({commit, state}) {
      axios.get('form-item/list', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data.formItemList)
      .then(form => {
        commit('SET_Form', form)
      })
    },
    loadAssessmentsList({commit, state}) {
      axios.get('admin/assessment/list/all', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then(response => response.data.assessmentList)
      .then(assessment => {
        commit('SET_Assessments', assessment)
      })
    },
  },
  modules: {},
  getters: {
    getCurrentToken: state => state.accessToken,
    getCurrentProfile: state => state.profile,
    getRole: state => state.role,
    getPage: state => state.profile.memberListPage,
    // getCompanies: state => state.companies,
    // getPrograms: state => state.programs,
    getCompanyID: state => id => state.companies.find(c => c.id === id),
    getMemberID: state => id => state.members.find(m => m.id === id),
    getProgramID: state => id => state.programs.find(p => p.id === id),
    getAssessorID: state => id => state.assessors.find(a => a.id === id),
    getFormItemID: state => id => state.formItems.find(f => f.id === id),
    getAssessmentsID: state => id => state.assessments.find(as => as.id === id),
    // getCurrentCompany: state => state.currentCompany,
    getPrograms: state => {
      return state.programs;
    },
    getCompanies: state => {
      return state.companies;
    },
    getProfile: state => {
      return state.profile;
    },
    getField,
  }
})