<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row mb-3">
            <div class="col-7">
              <p class="f-heading mb-0" v-if="formItems && !formItems.length">
                No forms created yet.
              </p>
            </div>
            <div class="col-5 text-end">
              <button
                class="btn btn-primary transition-3d-hover"
                v-if="!addFormItem"
                @click="addFormItem = !addFormItem"
              >
                Add questionaire
              </button>
              <button
                class="btn btn-outline-danger transition-3d-hover"
                v-if="addFormItem"
                @click="addFormItem = !addFormItem"
              >
                Cancel
              </button>
            </div>
          </div>

          <!-- ANCHOR Add form Item -->
          <div class="row mb-4" v-if="addFormItem == true">
            <div class="col-12">
              <div class="card dashboard-cards">
                <div class="card-header">
                  <div class="row">
                    <div class="col-12 col-md-3 col-lg-2">
                      <p class="f-heading mb-0">Select a category:</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="select-group flex-grow-1">
                        <select class="form-select" v-model="newItem.category">
                          <option value="PRODUCT">Product Maturity</option>
                          <option value="COMPETITIVENESS">
                            Competitiveness
                          </option>
                          <option value="INNOVATION">Innovation</option>
                          <option value="FINANCIAL">
                            Financial Capability
                          </option>
                          <option value="MANAGEMENT">
                            Management Capability
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-12 col-md-3 col-lg-2"><p class="f-heading">Title</p></div>
                    <div class="col-12 col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="newItem.title"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 col-lg-2"><p class="f-heading">Description</p></div>
                    <div class="col-12 col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="newItem.description"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 col-lg-2"><p class="f-heading">Weightage</p></div>
                    <div class="col-12 col-md-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="newItem.weightage"
                        :key-up="
                          (calculatedWeightage =
                            newItem.weightage / 100 + currentWeightage)
                        "
                      />
                      <div class="form-text">
                        Current weighteage :
                        {{ calculatedWeightage | calcWeightage }}%
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3 mt-5">
                    <div class="col-8">
                      <p class="mb-0 f-heading">Questions</p>
                    </div>
                    <div class="col-2 text-center">
                      <p class="f-heading">Min score</p>
                    </div>
                    <div class="col-2 text-center">
                      <p class="f-heading">Max score</p>
                    </div>
                  </div>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">1.</p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="newItem.option_1_name"
                            name="option_1_name"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_1_min"
                          name="option_1_min"
                        />
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_1_max"
                          name="option_1_min"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">2.</p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="newItem.option_2_name"
                            name="option_2_name"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_2_min"
                          name="option_2_min"
                        />
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_2_max"
                          name="option_2_min"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">3.</p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="newItem.option_3_name"
                            name="option_3_name"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_3_min"
                          name="option_3_min"
                        />
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_3_max"
                          name="option_3_min"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">4.</p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="newItem.option_4_name"
                            name="option_4_name"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_4_min"
                          name="option_4_min"
                        />
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_4_max"
                          name="option_4_min"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">5.</p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="newItem.option_5_name"
                            name="option_5_name"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_5_min"
                          name="option_5_min"
                        />
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_5_max"
                          name="option_5_min"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">6.</p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="newItem.option_6_name"
                            name="option_6_name"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_6_min"
                          name="option_6_min"
                        />
                      </div>
                      <div class="col-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newItem.option_6_max"
                          name="option_6_min"
                        />
                      </div>
                    </div>
                  </form>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 col-lg-2"><p class="f-heading">Additional name</p></div>
                    <div class="col-12 col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="newItem.additional_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button class="btn btn-primary" @click="createQuestion">
                    Add <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- ANCHOR View form -->
          <div class="row" v-if="formItems && formItems.length">
            <div class="col-12">
              <div class="card dashboard-cards">
                <div class="card-header d-flex">
                  <p class="f-heading mb-0 fs-6 text-dark">
                    Current weightage: {{ currentWeightage | calcWeightage }}%
                  </p>
                  <!-- <div class="edit-btns">
                    <button
                      class="btn btn-outline-primary"
                      @click="enableEditForm = true"
                      v-if="enableEditForm == false && currentRole == 'ADMIN'"
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-outline-danger"
                      @click.prevent="enableEditForm = false"
                      v-if="enableEditForm == true"
                    >
                      Cancel
                    </button>
                  </div> -->
                </div>

                <div class="card-body">
                  <div
                    class="accordion"
                    :id="'form-item-' + [f.id]"
                    v-for="f in formItems"
                    :key="f.id"
                  >
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#form-item-collapse' + [f.id]"
                          aria-expanded="true"
                          :aria-controls="'form-item-collapse' + [f.id]"
                        >
                          {{ f.category | category }}
                        </button>
                      </h2>
                      <div
                        :id="'form-item-collapse' + [f.id]"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-3">
                              <p class="f-heading">Title</p>
                            </div>
                            <div class="col-9">
                              <p>{{ f.title }}</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-3">
                              <p class="f-heading">Description</p>
                            </div>
                            <div class="col-9">
                              <p>{{ f.description }}</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-3">
                              <p class="f-heading">Weightage</p>
                            </div>
                            <div class="col-9">
                              <p>{{ f.weightage | calcWeightage }}%</p>
                            </div>
                          </div>
                          <div class="row mb-3 mt-4">
                            <div class="col-6 col-md-8">
                              <p class="mb-0 f-heading">Questions</p>
                            </div>
                            <div class="col-3 col-md-2 text-center">
                              <p class="f-heading">Min score</p>
                            </div>
                            <div class="col-3 col-md-2 text-center">
                              <p class="f-heading">Max score</p>
                            </div>
                          </div>
                          <div class="row mb-3" v-if="f.option_1_name">
                            <div class="col-6 col-md-8">
                              <p class="mb-0">1. {{ f.option_1_name }}</p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_1_min }}
                              </p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_1_max }}
                              </p>
                            </div>
                          </div>
                          <div class="row mb-3" v-if="f.option_2_name">
                            <div class="col-6 col-md-8">
                              <p class="mb-0">2. {{ f.option_2_name }}</p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_2_min }}
                              </p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_2_max }}
                              </p>
                            </div>
                          </div>
                          <div class="row mb-3" v-if="f.option_3_name">
                            <div class="col-6 col-md-8">
                              <p class="mb-0">3. {{ f.option_3_name }}</p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_3_min }}
                              </p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_3_max }}
                              </p>
                            </div>
                          </div>
                          <div class="row mb-3" v-if="f.option_4_name">
                            <div class="col-6 col-md-8">
                              <p class="mb-0">4. {{ f.option_4_name }}</p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_4_min }}
                              </p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_4_max }}
                              </p>
                            </div>
                          </div>
                          <div class="row mb-3" v-if="f.option_5_name">
                            <div class="col-6 col-md-8">
                              <p class="mb-0">5. {{ f.option_5_name }}</p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_5_min }}
                              </p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_5_max }}
                              </p>
                            </div>
                          </div>
                          <div class="row mb-3" v-if="f.option_6_name">
                            <div class="col-6 col-md-8">
                              <p class="mb-0">6. {{ f.option_6_name }}</p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_6_min }}
                              </p>
                            </div>
                            <div class="col-3 col-md-2">
                              <p class="text-center mb-0">
                                {{ f.option_6_max }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-end p-3">
                          <button
                            class="btn btn-outline-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            @click="
                              formItemID = f.id;
                              formItemName = f.title;
                              formItemCat = f.category;
                            "
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-primary ms-3"
                            @click="updateForm(f.id)"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ANCHOR Modal: delete program -->
          <div
            class="modal fade viewDetails"
            id="deleteModal"
            tabindex="-1"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title text-capitalize" id="deleteModalLabel">
                    Delete form item
                  </h5>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to delete this form item?</p>
                  <div class="d-flex">
                    <p class="f-heading">Category:</p>
                    <p class="ms-3">{{ formItemCat | category }}</p>
                  </div>
                  <div class="d-flex">
                    <p class="f-heading">Title:</p>
                    <p class="ms-3">{{ formItemName }}</p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn text-capitalize btn-danger"
                    @click="deleteFormItem()"
                  >
                    Delete <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- ANCHOR Toast -->
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="dangerToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-danger">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal, Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import axios from "axios";

export default {
  name: "ViewForm",
  components: {
    Sidebar,
  },
  data() {
    return {
      loading: false,
      enableEditForm: false,
      addFormItem: false,
      calculatedWeightage: this.currentWeightage,
      currentWeightage: 0,
      index: 1,
      disabledAdd: false,
      toastMsg: "",
      formItemName: "",
      formItemID: "",
      formItemCat: "",
      newItem: {
        category: "",
        title: "",
        description: "",
        option_1_name: "",
        option_1_min: "",
        option_1_max: "",
        option_2_name: "",
        option_2_min: "",
        option_2_max: "",
        option_3_name: "",
        option_3_min: "",
        option_3_max: "",
        option_4_name: "",
        option_4_min: "",
        option_4_max: "",
        option_5_name: "",
        option_5_min: "",
        option_5_max: "",
        option_6_name: "",
        option_6_min: "",
        option_6_max: "",
        additional_name: "",
        weightage: "",
      },
    };
  },
  filters: {
    category: function (c) {
      switch (c) {
        case "PRODUCT":
          return "Product Maturity";
          break;
        case "COMPETITIVENESS":
          return "Competitiveness";
          break;
        case "INNOVATION":
          return "Innocation";
          break;
        case "FINANCIAL":
          return "Financial Capability";
          break;
        case "MANAGEMENT":
          return "Management Capability";
          break;
      }
    },
    calcWeightage: function (w) {
      return (w * 100).toFixed(0);
    },
  },
  mounted() {
    this.$store.dispatch("loadFormList");
    this.calculateWeightage();
     this.deleteModal = new Modal(
      document.getElementById("deleteModal")
    );
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.dangerToast = new Toast(document.getElementById("dangerToast"), {
      delay: 5000,
      autohide: true,
    });
  },
  computed: {
    /** Call state form */
    ...mapState(["formItems"]),
    ...mapFields(["formItems.title"]),
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    currentRole() {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    increment() {
      if (this.index < 6) {
        this.index++;
        this.questions.push({ id: this.index });
      }
      if (this.index === 6) {
        this.disabledAdd = true;
        this.toastMsg = "Reached maximum number of questions";
        this.dangerToast.show();
      }
    },
    async createQuestion() {
      this.loading = true
      this.newItem.weightage = this.newItem.weightage / 100;
      const response = await axios.post(
        "admin/form-item/create",
        this.newItem,
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.addFormItem = false;
      this.toastMsg = response.data.msg;
      this.successToast.show();
      console.log(response.data.formItemDetails);
      this.$store.dispatch("loadFormList");
      this.calculateWeightage();
      this.loading = false
      this.newItem = {
        category: "",
        title: "",
        description: "",
        option_1_name: "",
        option_1_min: "",
        option_1_max: "",
        option_2_name: "",
        option_2_min: "",
        option_2_max: "",
        option_3_name: "",
        option_3_min: "",
        option_3_max: "",
        option_4_name: "",
        option_4_min: "",
        option_4_max: "",
        option_5_name: "",
        option_5_min: "",
        option_5_max: "",
        option_6_name: "",
        option_6_min: "",
        option_6_max: "",
        additional_name: "",
        weightage: "",
      }
    },
    async updateForm(id) {
      this.$router.push("/view-form-item/" + id);
    },
    async deleteFormItem() {
      this.loading = true
      try {
        const response = await axios.post(
          "admin/form-item/delete",
          {
            id: this.formItemID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );
        this.deleteModal.hide()
        this.toastMsg = response.data.msg
        this.successToast.show()
        this.loading = false
        this.$store.dispatch("loadFormList");
      } catch(error) {
        this.deleteModal.hide()
        this.toastMsg = "Failed to delete form item. Try again later"
        this.dangerToast.show()
        this.loading = false
      }
    },
    calculateWeightage() {
      for (let i = 0; i < this.formItems.length; i++) {
        this.currentWeightage += this.formItems[i].weightage;
      }
    },
  },
};
</script>