import jQuery from 'jquery'
import 'bootstrap'

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    'use strict'
  
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')
  
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
  
          form.classList.add('was-validated')
        }, false)
      })
  })()

  function assessmentRadioFunction(x) {
    var scoringDiv = x.parentElement.nextElementSibling;
    var input = x.nextElementSibling.nextElementSibling.firstElementChild;
    var attrName = input.getAttribute("name")
    var allInput = document.querySelectorAll('input[name="'+attrName+'"]')

    for(var i = 0; i < allInput.length; i++) {
      if(allInput[i].type == 'number') {
        allInput[i].disabled = true;
        allInput[i].removeAttribute("required");
      }else{
        console.log("could not find element");
        console.log(allInput[i])
      }
     }

     input.toggleAttribute("disabled");
     input.toggleAttribute("required");

  }
  
function deletePic() {
    document.getElementById('uploadPP').src='../assets/img/anonymous.jpg'
    document.getElementById('profilePic').val = '';
}

function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        $('#uploadPP').attr('src', e.target.result);
      }
      
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }
  
  jQuery("#profilePic").change(function() {
    readURL(this);
  });

  jQuery(".score-radio").change(function() {
    assessmentRadioFunction(this);
  });

  // jQuery(document).ready(function(){
  //   jQuery('.program-slide').slick({
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     dots: true,
  //     infinite: false,
  //   });
  // });
 