<template>
  <div>
    <!-- ANCHOR Success Modal -->
    <transition
      name="fade"
      mode="out-in"
      appear
      aria-modal="true"
      v-if="showSuccessModal"
    >
      <div
        class="modal fade show"
        id="modalSuccess"
        tabindex="-1"
        aria-labelledby="modalSuccessLabel"
        aria-hidden="true"
        style="display: block; padding-right: 17px"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-primary">
            <div class="modal-header">
              <h5 class="modal-title text-white" id="modalSuccessLabel">
                Hooray!
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal()"
              ></button>
            </div>
            <div class="modal-body">
              <p class="text-white">{{ msg }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- ANCHOR Fail Modal -->
    <transition
      name="fade"
      mode="out-in"
      appear
      aria-modal="true"
      v-if="showFailModal"
    >
      <div
        class="modal fade show modal-dialog-centered"
        id="modalFail"
        tabindex="-1"
        aria-labelledby="modalFailLabel"
        aria-hidden="true"
        style="display: block; padding-right: 17px"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-danger">
            <div class="modal-header">
              <h5 class="modal-title text-white" id="modalFailLabel">Oops!</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal()"
              ></button>
            </div>
            <div class="modal-body">
              <p class="text-white">{{ msg }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="row mb-4">
      <div class="col-12">
        <h2>My Profile</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="card dashboard-cards">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Edit profile</h5>
            <button
              class="btn btn-outline-primary"
              @click="enableEditForm = true"
              v-if="enableEditForm == false"
            >
              Edit
            </button>
            <button
              class="btn btn-outline-danger"
              @click.prevent="
                resetProfile;
                enableEditForm = false;
              "
              v-if="enableEditForm == true"
            >
              Cancel
            </button>
          </div>
          </div>
          <div class="card-body">
            <!-- ANCHOR START: Form update profile -->
            <form
              id="member-profile"
              class="needs-validation"
              novalidate="novalidate"
            >
              <div class="profile-pic">
                <div class="row justify-content-center">
                  <!-- <div class="col-4 col-md-3">
                    <p class="f-heading">Profile picture</p>
                  </div> -->
                  <div class="col-12">
                    <div class="img-wrapper position-relative mx-auto">
                        <button
                          class="btn-delete"
                          v-if="enableEditForm == true && profile_photo"
                          @click.prevent="
                            file = null;
                            profile_photo = null;
                          "
                        >
                          <i class="bi bi-x-circle-fill"></i>
                        </button>
                        <img
                          src="@/assets/img/anonymous.jpg"
                          alt="profile picture"
                          class="rounded-circle"
                          v-if="!profile_photo"
                        />
                        <img
                          :src="'../storage/app/'+[profile_photo]"
                          class="rounded-circle"
                          alt="profile_photo"
                          v-if="profile_photo"
                        />
                  </div>
                  <div class="col-12">
                    <div class="input-wrapper profilepicture text-center mx-auto">
                        <label
                          for="#profilePic"
                          class="btn btn-primary mx-auto"
                          v-if="enableEditForm == true"
                          >Upload Photo</label
                        >
                        <input
                          type="file"
                          id="file"
                          @change="selectFile"
                          :disabled="enableEditForm == false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="personal-info">
                <!-- ANCHOR : Title -->
                <div class="row align-items-center">
                  <div class="col-4 col-sm-3"><p class="f-heading">Title</p></div>
                  <div class="col-8 col-sm-3">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{title}}</p>
                      <select
                        class="form-select"
                        name="title"
                        aria-label="Default select example"
                        v-model="title"
                        v-if="enableEditForm == true"
                        :disabled="enableEditForm == false"
                      >
                        <option disabled value="">Pick a title</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Ms">Ms</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Full name -->
                <div class="row">
                  <div class="col-4 col-sm-3">
                    <p class="f-heading">Name</p>
                  </div>
                  <div class="col-8 col-lg-6">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{name}}</p>
                      <input
                        type="text"
                        :class="[
                          enableEditForm == false
                            ? 'form-control-plaintext'
                            : 'form-control',
                        ]"
                        name="name"
                        id="name"
                        placeholder="Your name"
                        v-model="name"
                        v-if="enableEditForm == true"
                        :disabled="enableEditForm == false"
                        required
                      />
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Gender -->
                <div class="row">
                  <div class="col-4 col-sm-3"><p class="f-heading">Gender</p></div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{gender | gender}}</p>
                      <select
                        class="form-select"
                        name="title"
                        aria-label="Default select example"
                        v-model="gender"
                        v-if="enableEditForm == true"
                        :disabled="enableEditForm == false"
                      >
                        <option disabled value="">Pick a title</option>
                        <option value="F">Female</option>
                        <option value="M">Male</option>
                        <option value="O">Rather not say</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Email -->
                <div class="row align-items-center">
                  <div class="col-4 col-sm-3">
                    <p class="mb-0 f-heading">Email</p>
                  </div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{email}}</p>
                      <input
                        type="email"
                        :class="[
                          enableEditForm == false
                            ? 'form-control-plaintext'
                            : 'form-control',
                        ]"
                        name="email"
                        id="email"
                        placeholder="johndoe@email.com"
                        aria-label="Nataly"
                        data-msg="Please enter first your email"
                        v-model="email"
                        v-if="enableEditForm == true"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Mobile number -->
                <div class="row align-items-start">
                  <div class="col-4 col-sm-3"><p class="f-heading">Mobile number</p></div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{mobile_no}}</p>
                      <input
                        type="text"
                        :class="[
                          enableEditForm == false
                            ? 'form-control-plaintext'
                            : 'form-control',
                        ]"
                        name="mobile_no"
                        id="mobile_no"
                        v-model="mobile_no"
                        v-if="enableEditForm == true"
                        :disabled="enableEditForm == false"
                      />
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : IC/Passport-->
                <div class="row">
                  <div class="col-4 col-sm-3">
                    <p class="f-heading">IC/Passport</p>
                  </div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{ic_passport_no}}</p>
                      <input
                        type="text"
                        :class="[
                          enableEditForm == false
                            ? 'form-control-plaintext'
                            : 'form-control',
                        ]"
                        name="ic_passport_no"
                        id="ic_passport_no"
                        v-model="ic_passport_no"
                        v-if="enableEditForm == true"
                        :disabled="enableEditForm == false"
                      />
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Nationality -->
                <div class="row">
                  <div class="col-4 col-sm-3">
                    <p class="f-heading">Nationality</p>
                  </div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="js-form-message form-group">
                      <p v-if="enableEditForm == false">: {{nationality}}</p>
                      <input
                        type="text"
                        :class="[
                          enableEditForm == false
                            ? 'form-control-plaintext'
                            : 'form-control',
                        ]"
                        name="nationality"
                        id="nationality"
                        v-model="nationality"
                        v-if="enableEditForm == true"
                        :disabled="enableEditForm == false"
                      />
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Created at -->
                <div class="row">
                  <div class="col-4 col-sm-3">
                    <p class="f-heading">Date joined</p>
                  </div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <p>{{ created_at | moment }}</p>
                  </div>
                </div>

                <!-- ANCHOR : Membership -->
                <div
                  class="row"
                  v-if="role == 'FREE_MEMBER' || role == 'PAID_MEMBER'"
                >
                  <div class="col-4 col-sm-3">
                    <p class="f-heading">Membership</p>
                  </div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="d-flex align-items-center">
                      <p class="mb-0">
                        {{ role | member }}
                        <router-link :to="{ name: 'Upgrade' }" class="ms-3" v-if="role == 'FREE_MEMBER'"
                          ><i class="fas fa-rocket"></i> Upgrade</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <!-- ANCHOR : Email notification -->
                <div
                  class="row"
                  v-if="role == 'FREE_MEMBER' || role == 'PAID_MEMBER'"
                >
                  <div class="col-4 col-sm-3">
                    <p class="f-heading">Email notification</p>
                  </div>
                  <div class="col-8 col-sm-8 col-lg-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="email_notification"
                        v-model="email_notification"
                        id="email_notification"
                        :disabled="enableEditForm == false"
                      />
                      <label class="form-check-label" for="email_notification">
                        Yes, I want to receive email notification.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ANCHOR : Submit button -->
              <div class="submit-form" v-if="enableEditForm == true">
                <button
                  class="btn btn-primary"
                  type="submit"
                  @click.prevent="handleSubmit"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
      appear
      aria-modal="true"
      v-if="showModal"
    >
      <div class="modal-backdrop fade show"></div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapFields } from "vuex-map-fields";
import moment from "moment";

export default {
  name: "EditProfile",
  /** ANCHOR Data */
  data() {
    return {
      updateSuccessfully: "",
      msg: "",
      showSuccessModal: false,
      showFailModal: false,
      showModal: false,
      selectedFile: null,
      enableEditForm: false,
      file: null,
    };
  },
  mounted() {
    this.$store.dispatch("loadProfile");
    this.$store.commit("setPageType", 'dashboard')
  },
  computed: {
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

    ...mapFields([
      "profile.name",
      "profile.title",
      "profile.email",
      "profile.gender",
      "profile.mobile_no",
      "profile.ic_passport_no",
      "profile.nationality",
      "profile.email_notification",
      "profile.role",
      "profile.profile_photo",
      "profile.created_at",
    ]),
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMMM YYYY");
    },

    /** ANCHOR Member filter */
    member: function (role) {
      if (role == "FREE_MEMBER") {
        return "Free Membership";
      } else if (role == "PAID_MEMBER") {
        return "PRO Membership";
      } else if (role == "ASSESSOR") {
        return "Assessor";
      } else {
        return role;
      }
    },

    gender: function(gender) {
      if(gender == "F") {
        return "Female";
      } else if (gender == "M") {
        return "Male";
      } else {
        return '-';
      }
    }
  },
  methods: {
    selectFile(event) {
      console.log(event.target.files[0]);
      this.file = event.target.files[0];
    },
    async handleSubmit() {
      try {
        let formData = new FormData();
        formData.append("profile_photo", this.file);

        await axios.post("user/profile-photo", formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        });

        const response = await axios.post(
          "user/profile",
          {
            name: this.name,
            email: this.email,
            title: this.title,
            gender: this.gender,
            mobile_no: this.mobile_no,
            ic_passport_no: this.ic_passport_no,
            nationality: this.nationality,
            email_notification: this.email_notification == false ? "0" : "1",
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );
        // console.log(response);
        this.msg = response.data.msg;
        this.enableEditForm = false;
        this.$store.dispatch("loadProfile");
        this.updateSuccessfully = "success";
        this.showModalSuccess();
        var elmnt = document.getElementById("DashboardWrap");
        elmnt.scrollIntoView();
      } catch (error) {
        this.updateSuccessfully = "fail";
        this.msg = "Sorry, we we're unable to update your profile.";
        this.showModalFail();
      }
    },
    async resetProfile() {
      this.$store.dispatch("loadProfile");
    },
    showModalSuccess() {
      var body = document.body;
      body.classList.add("modal-open");
      body.setAttribute("data-bs-padding-right", "");
      this.showSuccessModal = true;
      this.showModal = true;
    },
    showModalFail() {
      var body = document.body;
      body.classList.add("modal-open");
      body.setAttribute("data-bs-padding-right", "");
      this.showFailModal = true;
      this.showModal = true;
    },
    closeModal() {
      var body = document.body;
      body.classList.remove("modal-open");
      body.removeAttribute("data-bs-padding-right");
      this.showSuccessModal = false;
      this.showFailModal = false;
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.modal-content {
  border-radius: 0.75rem;
  border: 0;
}
.modal-header {
  border-bottom: 1px solid rgba(222, 226, 230, 0.24);
  align-items: flex-start;
  button {
    margin-left: 30px;
  }
}
</style>