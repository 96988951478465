<template>
  <div class="sidebar-menu">
    <div class="menu-wrapper" id="sidebarMainMenu">
      <!-- offcanvas button -->
      <button
        class="btnOffCanvas hamburger hamburger--spin is-active"
        @click="closeMenu()"
        id="menuBtnSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

      <div class="member-info">
        <div class="member-img" v-if="profile.profile_photo">
          <img
            :src="'../storage/app/' + profile.profile_photo"
            class="img-fluid rounded-circle"
            alt="profile_photo"
          />
        </div>
        <h5 class="member-name mb-2">{{ profile.name }}</h5>
        <p class="member-email text-muted px-2">{{ profile.email }}</p>
        <span class="badge bg-primary">{{ profile.role | member }}</span>
      </div>

      <div class="'collapse show" id="accountMenu">
        <div class="member-settings profile">
          <ul class="list-unstyled">
            <!-- ANCHOR LINK: Dashboard : member -->
            <li
              :class="[$route.name == 'Dashboard' ? 'active' : '']"
              v-on:click="routeTo('Dashboard')"
              v-if="
                profile.role == 'FREE_MEMBER' || profile.role == 'PAID_MEMBER'
              "
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-house-door"></i>Home</a
              >
            </li>
            <!-- ANCHOR LINK: Dashboard : admin -->
            <li
              :class="[$route.name == 'Admin dashboard' ? 'active' : '']"
              v-on:click="routeTo('Admin dashboard')"
              v-if="profile.role == 'ADMIN'"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-house-door"></i>Home</a
              >
            </li>

            <!-- ANCHOR LINK: Dashboard : assessor -->
            <li
              :class="[$route.name == 'Assessor dashboard' ? 'active' : '']"
              v-on:click="routeTo('Assessor dashboard')"
              v-if="profile.role == 'ASSESSOR'"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-house-door"></i>Home</a
              >
            </li>

            <!-- ANCHOR LINK: Inbox -->
            <li
              :class="[$route.name == 'Inbox' ? 'active' : '']"
              v-on:click="routeTo('Inbox')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-envelope"></i>Inbox</a
              >
            </li>
          </ul>
        </div>
        <div class="member-settings profile">
          <h6 class="text-uppercase">Account</h6>

          <ul class="list-unstyled">
            <!-- ANCHOR LINK: My Profile -->
            <li
              :class="[$route.name == 'My Profile' ? 'active' : '']"
              v-on:click="routeTo('My Profile')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-person-badge"></i>My profile</a
              >
            </li>

            <!-- ANCHOR LINK: Security -->
            <li
              :class="[$route.name == 'Login & Security' ? 'active' : '']"
              v-on:click="routeTo('Login & Security')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-shield-shaded"></i>Login & security</a
              >
            </li>
          </ul>
        </div>

        <!-- NOTE Company menus -->
        <div
          class="member-settings profile"
          v-if="profile.role == 'PAID_MEMBER' || profile.role == 'FREE_MEMBER'"
        >
          <h6 class="text-uppercase">Company</h6>

          <ul class="list-unstyled">
            <!-- ANCHOR LINK: My companies -->
            <li
              :class="[$route.name == 'My Companies' ? 'active' : '']"
              v-on:click="routeTo('My Companies')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-diagram-3"></i>My companies</a
              >
            </li>

            <!-- ANCHOR LINK: Add members -->
            <!-- <li
              :class="[$route.name == 'Add Members' ? 'active' : '']"
              v-on:click="closeMenu()"
            >
              <router-link
                :to="{ name: 'Add Members' }"
                :class="[profile.role != 'PAID_MEMBER' ? 'diasbled' : '']"
                ><i class="bi bi-person-plus"></i>Add members
                <span class="badge bg-secondary ms-2 floating"
                  >PRO</span
                ></router-link
              >
            </li> -->
          </ul>
        </div>

        <!-- NOTE Enrollments menus -->
        <div
          class="member-settings profile"
          v-if="profile.role == 'PAID_MEMBER' || profile.role == 'FREE_MEMBER'"
        >
          <h6 class="text-uppercase">Enrollment</h6>

          <ul class="list-unstyled">
            <!-- ANCHOR LINK: View all enrollment -->
            <li
              :class="[$route.name == 'Programs' ? 'active' : '']"
              v-on:click="routeTo('Programs')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-briefcase"></i>View programs</a
              >
            </li>

            <!-- ANCHOR LINK: My enrollment -->
            <li
              :class="[$route.name == 'My Enrollments' ? 'active' : '']"
              v-on:click="routeTo('My Enrollments')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-journal-bookmark"></i>My enrollments</a
              >
            </li>
          </ul>
        </div>

        <!-- NOTE Admin: view assessors menus -->
        <div class="member-settings profile " v-if="profile.role == 'ADMIN'">
          <h6 class="text-uppercase">Assessors</h6>

          <ul class="list-unstyled ">
            <!-- NOTE LINK: View all assessors -->
            <li
              :class="[$route.name == 'View Assessors' ? 'active' : '']"
              v-on:click="routeTo('View Assessors')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-vector-pen"></i>View assessors</a
              >
            </li>
            <!-- NOTE LINK: View all assessments -->
            <li
              :class="[$route.name == 'View Assessment' ? 'active' : '']"
              v-on:click="routeTo('View Assessment')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-paperclip"></i>View assessments</a
              >
            </li>
          </ul>
        </div>

        <!-- NOTE Admin: view members menus -->
        <div class="member-settings profile" v-if="profile.role == 'ADMIN'">
          <h6 class="text-uppercase">Members</h6>

          <ul class="list-unstyled">
            <!-- NOTE LINK: View all members -->
            <li
              :class="[$route.name == 'View Members' ? 'active' : '']"
              v-on:click="routeTo('View Members')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-people"></i>View members</a
              >
            </li>
          </ul>
        </div>

        <!-- NOTE Admin: view companies menus -->
        <div class="member-settings profile" v-if="profile.role == 'ADMIN'">
          <h6 class="text-uppercase">Companies</h6>

          <ul class="list-unstyled">
            <!-- NOTE LINK: View all companies -->
            <li
              :class="[$route.name == 'View Companies' ? 'active' : '']"
              v-on:click="routeTo('View Companies')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-building"></i>View companies</a
              >
            </li>
          </ul>
        </div>

        <!-- NOTE Admin: view program menus -->
        <div class="member-settings profile" v-if="profile.role == 'ADMIN'">
          <h6 class="text-uppercase">Programs</h6>

          <ul class="list-unstyled">
            <!-- NOTE LINK: View all programs -->
            <li
              :class="[$route.name == 'View Programs' ? 'active' : '']"
              v-on:click="routeTo('View Programs')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-clipboard-minus"></i>View programs</a
              >
            </li>
            <!-- NOTE LINK: View form template -->
            <li
              :class="[$route.name == 'View Form' ? 'active' : '']"
              v-on:click="routeTo('View Form')"
            >
              <a href="javascript:void(0)"
                ><i class="bi bi-ui-checks"></i>Submission form</a
              >
            </li>
          </ul>
        </div>

        <div class="member-settings profile only-desktop">
          <ul class="list-unstyled">
            <!-- ANCHOR LINK: Logout -->
            <li>
              <a
                href="javascript:void(0)"
                v-on:click="
                  closeMenu();
                  logout();
                "
                ><i class="bi bi-box-arrow-right"></i>Sign out</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      // profile_pic: profile.profile_photo
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getCurrentProfile;
    },
  },
  filters: {
    /** ANCHOR Member filter */
    member: function (role) {
      if (role == "FREE_MEMBER") {
        return "Free Membership";
      } else if (role == "PAID_MEMBER") {
        return "PRO Membership";
      } else if (role == "ASSESSOR") {
        return "Assessor";
      } else if (role == "ADMIN"){
        return "Admin";
      } else {
        return 'Stranger'
      }
    },
  },
  methods: {
    currentRoute() {
      return this.$route.name;
    },
    closeMenu() {
      var btn = document.getElementById("sidebarMainMenu");
      var thisbtn = document.getElementById("menuBtnSidebar");
      var overlaySidebar = document.getElementById("overlaySidebar");
      var body = document.body;
      if (body.classList.contains("menu-is-open")) {
        body.classList.remove("menu-is-open");
      }

      if (thisbtn.classList.contains("is-active")) {
        thisbtn.classList.remove("is-active");
      }

      if (btn.classList.contains("show")) {
        btn.classList.remove("show");
      }

      if (overlaySidebar.classList.contains("show")) {
        overlaySidebar.classList.remove("show");
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Home" });
    },
    routeTo(routeName) {
      this.closeMenu();
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
</style>