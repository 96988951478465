<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->

          <!-- ANCHOR Company list -->

          <div class="company-list-wrapper">
            <div class="row justify-content-end justify-content-md-between">
              <div class="col-4">
                <button
                  class="btn btn-link fc-primary mb-3 mb-md-0 ms-auto ms-md-0"
                  @click="refresh"
                >
                  <i class="bi bi-arrow-counterclockwise me-2"></i>
                  <span>Refresh</span>
                </button>
              </div>
              <div class="col-12 col-sm-6 col-xl-4">
                <form class="search-bar">
                  <div class="input-group flex-nowrap">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search member"
                      v-model="searchValue"
                    />
                    <span class="input-group-text" id="addon-wrapping">
                      <button
                        class="btn btn-link"
                        type="submit"
                        @click.prevent="searchMember"
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>

            <div class="table-responsive mt-4">
              <table class="table table-hover table-borderless white-table-list">
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="d-flex">
                        <button
                          class="btn btn-link p-0 table-sort-btn me-2"
                          @click="
                            sortASC = !sortASC;
                            searchMember();
                          "
                        >
                          <i
                            :class="
                              'bi bi-sort-alpha-' + [!sortASC ? 'down' : 'up']
                            "
                          ></i>
                        </button>
                        Name
                      </div>
                    </th>
                    <th scope="col" class="text-center only-mobile">
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <span>Membership</span>
                      </div>
                    </th>
                    <th scope="col" class="text-center only-mobile">
                      <div class="d-flex align-items-center">
                        <span>Status</span>
                      </div>
                    </th>
                    <th scope="col" class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="member in members" :key="member.id">
                    <th>{{ member.name }}</th>
                    <td class="text-center only-mobile">
                      {{ member.role | member }}
                    </td>

                    <td class="text-start only-mobile">
                      {{
                        member.is_block == 1
                          ? "Blocked"
                          : member.is_delete == 1
                          ? "Deleted"
                          : member.paid_at != null &&
                            member.role == "FREE_MEMBER"
                          ? "Pending upgrade"
                          : member.verified_at == null
                          ? "Not verified"
                          : "Active"
                      }}
                    </td>
                    <td>
                      <div class="action d-flex justify-content-center">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#memberDetails"
                          @click="
                            memberID = member.id;
                            memberName = member.name;
                            findMember(member.id);
                          "
                          class="btn btn-link text-primary text-center py-0"
                        >
                          <i class="bi bi-eye"></i>
                        </button>
                        <!-- <button
                          data-bs-toggle="modal"
                          data-bs-target="#approveMember"
                          v-if="
                            member.paid_at != null &&
                            member.role == 'FREE_MEMBER'
                          "
                          @click="
                            memberID = member.id;
                            memberName = member.name;
                            approveMember(member.id);
                          "
                          class="btn btn-link text-success text-center py-0"
                        >
                          <i class="bi bi-check2-circle"></i>
                        </button> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <nav class="d-flex justify-content-end">
            <ul class="pagination pagination-sm" v-if="totalPages > 2">
              <li
                :class="'page-item ' + [p == currentPage && 'active']"
                v-for="p in totalPages"
                :key="p"
              >
                <a
                  href="javascript:void(0)"
                  class="page-link fc-primary"
                  @click="
                    currentPage = p;
                    searchMember();
                  "
                  >{{ p }}</a
                >
              </li>
            </ul>
            <a
              href="javascript:void(0)"
              class="btn btn-link fc-primary ms-auto"
              v-if="totalPages == 2"
              @click="
                currentPage == 2 ? (currentPage = 1) : (currentPage = 2);
                searchMember();
              "
              >{{ currentPage == 1 ? "Next" : "Previous" }}</a
            >
          </nav>

          <!--ANCHOR Modal: view member -->
          <div
            class="modal fade viewDetails"
            id="memberDetails"
            tabindex="-1"
            aria-labelledby="memberDetailsLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="memberDetailsLabel">
                    Member Id : #{{ memberSelected.id }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="memberSelected = []"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex justify-content-center">
                    <img
                      :src="'@/assests/storage/' + memberSelected.profile_photo"
                      alt="profile-picture"
                      class="img-circle mx-auto mb-5"
                      v-if="memberSelected.profile_photo"
                    />
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Title</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.title || "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Name</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.name }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Gender</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.gender | gender }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Email</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        <a :href="'mailto:' + memberSelected.email"
                          ><u>{{ memberSelected.email }}</u></a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">IC/Passport</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.ic_passport_no || "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Date joined</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.created_at | moment }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Email notification</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{
                          memberSelected.email_notification == 0 ? "Off" : "On"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Membership</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{
                          memberSelected.role == "FREE_MEMBER"
                            ? "Free Membership"
                            : memberSelected.role == "PAID_MEMBER"
                            ? "Paid Membership"
                            : "-"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Contact number</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        <a :href="'tel:' + memberSelected.mobile_no"
                          ><u>{{ memberSelected.mobile_no }}</u></a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Status</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{
                          memberSelected.is_block == 1
                            ? "Blocked"
                            : memberSelected.is_delete == 1
                            ? "Deleted"
                            : "Active"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    v-if="
                      memberSelected.paid_at == null &&
                      memberSelected.role == 'FREE_MEMBER'
                    "
                    class="btn btn-outline-success"
                    @click="approveMember(memberSelected.id)"
                  >
                    Approve
                  </button>
                  <button
                    :class="
                      'btn ms-3 ' +
                      [
                        memberSelected.is_block == 1
                          ? 'btn-outline-primary'
                          : 'btn-outline-danger',
                      ]
                    "
                    @click="blockMember(memberSelected.id)"
                  >
                    {{ memberSelected.is_block == 1 ? "Unblock" : "Block" }}
                  </button>
                  <button
                    :class="
                      'btn ms-3 ' +
                      [
                        memberSelected.is_delete == 1
                          ? 'btn-primary'
                          : 'btn-danger',
                      ]
                    "
                    @click="deleteMember(memberSelected.id)"
                  >
                    {{ memberSelected.is_delete == 1 ? "Restore" : "Delete" }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--ANCHOR Modal: approve member -->
          <!-- <div
            class="modal fade"
            id="approveMember"
            tabindex="-1"
            aria-labelledby="approveMemberLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title mx-auto" id="approveMemberLabel">
                    Approve member
                  </h5>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to approve this member:</p>
                  <p class="f-heading me-3">
                    #{{ memberID }} : {{ memberName }}
                  </p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-dismiss="modal"
                    @click="
                      memberID = '';
                      memberName = '';
                    "
                  >
                    Cancel
                  </button>
                  <button type="button" class="btn btn-primary">Approve</button>
                </div>
              </div>
            </div>
          </div> -->

          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="dangerToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-danger">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal, Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  name: "ViewMembers",
  components: {
    Sidebar,
  },
  data() {
    return {
      memberID: "",
      memberName: "",
      searchValue: "",
      memberSelected: [],
      searchedUser: [],
      sortASC: false,
      filterMembership: 0,
      currentPage: 1,
      totalPages: "",
      toastMsg: "",
      limit: "",
    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMMM YYYY");
    },

    /** ANCHOR Member filter */
    member: function (role) {
      if (role == "FREE_MEMBER") {
        return "Free";
      } else if (role == "PAID_MEMBER") {
        return "Paid";
      } else if (role == "ASSESSOR") {
        return "Assessor";
      } else {
        return role;
      }
    },

    gender: function (gender) {
      if (gender == "F") {
        return "Female";
      } else if (gender == "M") {
        return "Male";
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadMemberList");
    this.totalPages = this.memberListPage;
    this.memberDetailsModal = new Modal(
      document.getElementById("memberDetails")
    );
    this.approveMemberModal = new Modal(
      document.getElementById("approveMember")
    );
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.dangerToast = new Toast(document.getElementById("dangerToast"), {
      delay: 5000,
      autohide: true,
    });
  },
  computed: {
    /** Call state members */
    ...mapState(["members"]),
    ...mapState(["memberListPage"]),

    /** Find member by ID */
    ...mapGetters(["getMemberID"]),

    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

    page() {
      return this.$store.getters.getPage;
    },
  },
  methods: {
    refresh() {
      this.$store.commit("CLEAR_Members");
      this.$store.dispatch("loadMemberList");
      this.currentPage = 1;
      this.searchValue = "";
      this.searchedUser = [];
      this.totalPages = this.$store.getters.getPage;
      this.toastMsg = "Refreshed members list";
      this.primaryToast.show();
    },
    findMember(id) {
      this.memberSelected = this.getMemberID(id);
      // console.log(this.memberSelected);
    },
    async searchMember() {
      this.$store.commit("CLEAR_Members");
      const response = await axios.get(
        "admin/member/list",
        {
          params: {
            search_by: "name",
            search_value: this.searchValue,
            order: this.sortASC ? "ASC" : "DESC",
            limit: this.limit,
            page: this.currentPage,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      let newList = response.data.userList;
      this.totalPages = response.data.totalPage;
      this.$store.commit("setMembers", newList);
      this.searchedUser = newList;
    },
    async deleteMember(id) {
      console.log("Trying to delete: " + id);
      await axios.post(
        "admin/member/delete",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.$store.dispatch("loadMemberList");
      this.memberDetailsModal.hide();

      if (this.memberSelected.is_delete == 1) {
        this.toastMsg = "Member restored";
        this.successToast.show();
      } else {
        this.toastMsg = "Member deleted";
        this.dangerToast.show();
      }
    },
    async blockMember(id) {
      console.log("Trying to block: " + id);
      await axios.post(
        "admin/member/block",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.$store.dispatch("loadMemberList");
      this.memberDetailsModal.hide();
      if (this.memberSelected.is_block == 1) {
        this.toastMsg = "Member unblocked";
        this.successToast.show();
      } else {
        this.toastMsg = "Member blocked";
        this.dangerToast.show();
      }
    },
    async approveMember(id) {
      console.log("Trying to approve: " + id);
      await axios.post(
        "admin/member/approve",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.$store.dispatch("loadMemberList");
      this.memberDetailsModal.hide();
      this.toastMsg = "Member approved";
      this.successToast.show();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
