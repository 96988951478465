<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h1>About CocoonX</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-6">
          <hr class="my-0" />
        </div>
      </div>
    </div>

    <div class="section smaller-padding">
      <div class="container space-2 space-lg-3">
        <div class="row justify-content-around">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <h2>
              Established by <br />
              Serba Dinamik Group Bhd
            </h2>
          </div>
          <div class="col-lg-6">
            <div class="about-intro">
              <p>
                CocoonX is a part of the Group’s Innovation Hub that aims to
                propel high-potential startups to become the next tech giants.
              </p>
              <p>
                Tapping into Serba Dinamik global market access, academia
                collaboration and vast industrial network, CocoonX offers
                distinctive venture building opportunity and research &
                development resources for the startups to engage directly either
                through the Startup Growth Track or Open Innovation
                Collaboration.
              </p>
              <p>
                Focusing on nurturing growth and delivering results, CocoonX
                tailors its perks and facilities to the needs of the startup and
                its maturity without the hassle of bureaucracy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="section smaller-padding inner-page bg-primary-color dark text-white"
    >
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-12 col-md-8">
            <div class="copy-wrapper pt-5 text-center">
              <h3 class="regular">
                Wondering what programme suits you the most?
              </h3>
              <p class="text-white-50">Follow the steps below</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div
              class="step-wrapper d-md-flex align-items-start justify-content-around"
            >
              <div class="step-item-icon text-center">
                <img
                  src="@/assets/img/icon-steps-(7).png"
                  alt=""
                  class="img-fluid mb-3"
                  style="max-width: 60px; opacity: 0.6"
                />
                <p class="fs-5 f-heading text-white">Enroll our program</p>
                <p class="text-white-50">
                  We will score your submission and find the right tools for you
                </p>
              </div>
              <div class="step-item-icon text-center">
                <img
                  src="@/assets/img/icon-steps-(5).png"
                  alt=""
                  class="img-fluid mb-3"
                  style="max-width: 60px; opacity: 0.6"
                />
                <p class="fs-5 f-heading text-white">We'll give you a call</p>
                <p class="text-white-50">
                  You will be called for an interview session for both parties
                  to assess your best fit
                </p>
              </div>
              <!-- <div class="step-item-icon text-center">
                        <img src="@/assets/img/icon-steps-(6).png" alt="" class="img-fluid mb-3" style="max-width: 60px; opacity: .6;">
                        <p class="fw-bold">Technical evaluation</p>
                        <p>We will evaluate and identify your the strenghts and weaknesses</p>
                    </div> -->
              <div class="step-item-icon text-center">
                <img
                  src="@/assets/img/icon-steps-(1).png"
                  alt=""
                  class="img-fluid mb-3"
                  style="max-width: 60px; opacity: 0.6"
                />
                <p class="fs-5 f-heading text-white">
                  Technical & Business evaluation
                </p>
                <p class="text-white-50">
                  We will evaluate and identify your the strenghts and
                  weaknesses
                </p>
                <p></p>
              </div>
              <div class="step-item-icon text-center">
                <img
                  src="@/assets/img/icon-steps-(3).png"
                  alt=""
                  class="img-fluid mb-3"
                  style="max-width: 60px; opacity: 0.6"
                />
                <p class="fs-5 f-heading text-white">Pitch us!</p>
                <p class="text-white-50">
                  Give use your best pitch to our investors or internal
                  investment boards
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-12 col-md-8 text-center">
            <router-link
              :to="{ name: 'Join Community' }"
              class="btn btn-outline-primary transition-3d-hover px-3 px-xl-5 right-icon"
              ><span>Send your applications now</span>
              <i class="bi bi-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <CTA />
  </div>
</template>

<script>
import CTA from "../components/CTA";
export default {
  name: "About",
  components: {
    CTA,
  },
};
</script>