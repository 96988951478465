<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row mb-3">
            <div class="col-6">
              <button
                class="btn btn-primary transition-3d-hover"
                @click="goBack()"
              >
                <i class="bi bi-arrow-left"></i> Back
              </button>
            </div>
            <div class="col-6 text-end">
              <div class="edit-btns">
                <button
                  class="btn btn-outline-primary"
                  @click="enableEditForm = true"
                  v-if="enableEditForm == false && currentRole == 'ADMIN'"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="resetForm()"
                  v-if="enableEditForm == true"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12">
              <div class="card dashboard-cards">
                <div class="card-header">
                  <div class="row" v-if="enableEditForm == false">
                    <d class="d-flex">
                      <p class="f-heading mb-0">Category :</p>
                      <p class="ms-3 mb-0">
                        {{ updateItem.category | category }}
                      </p>
                    </d>
                  </div>
                  <div class="row" v-if="enableEditForm == true">
                    <div class="col-12 col-md-3 col-lg-2">
                      <p class="f-heading mb-md-0">Select a category:</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="select-group flex-grow-1">
                        <select
                          class="form-select"
                          v-model="updateItem.category"
                        >
                          <option value="PRODUCT">Product Maturity</option>
                          <option value="COMPETITIVENESS">
                            Competitiveness
                          </option>
                          <option value="INNOVATION">Innovation</option>
                          <option value="FINANCIAL">
                            Financial Capability
                          </option>
                          <option value="MANAGEMENT">
                            Management Capability
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!-- ANCHOR Title -->
                  <div class="row mb-3" v-if="enableEditForm == false">
                    <div class="col-3"><p class="f-heading mb-0">Title</p></div>
                    <div class="col">
                      <p class="ms-3 mb-0">: {{ updateItem.title }}</p>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="enableEditForm == true">
                    <div class="col-12 col-md-3 col-lg-2">
                      <p class="f-heading">Title</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="updateItem.title"
                      />
                    </div>
                  </div>

                  <!-- ANCHOR Description -->
                  <div class="row mb-3" v-if="enableEditForm == false">
                    <div class="col-3">
                      <p class="f-heading mb-0">Description</p>
                    </div>
                    <div class="col">
                      <p class="ms-3 mb-0">: {{ updateItem.description }}</p>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="enableEditForm == true">
                    <div class="col-12 col-md-3 col-lg-2">
                      <p class="f-heading">Description</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="updateItem.description"
                      />
                    </div>
                  </div>

                  <!-- ANCHOR Weightage -->
                  <div class="row mb-3" v-if="enableEditForm == false">
                    <div class="col-3">
                      <p class="f-heading mb-0">Weightage</p>
                    </div>
                    <div class="col d-flex">
                      <p class="ms-3 mb-0">: {{ updateItem.weightage }} %</p>
                      <p class="ms-3 mb-0 text-muted">
                        (Current weightage :
                        {{ calculatedWeightage || currentWeightage }}%)
                      </p>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="enableEditForm == true">
                    <div class="col-12 col-md-3 col-lg-2">
                      <p class="f-heading">Weightage</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="updateItem.weightage"
                        :key-up="
                          (calculatedWeightage =
                            +parseInt(updateItem.weightage) +
                            parseInt(currentWeightage))
                        "
                      />
                      <div class="form-text">
                        Current weightage :
                        {{ calculatedWeightage || currentWeightage }}%
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3 mt-5">
                    <div class="col-8">
                      <p class="mb-0 f-heading">Questions</p>
                    </div>
                    <div class="col-2 text-center">
                      <p class="f-heading">Min score</p>
                    </div>
                    <div class="col-2 text-center">
                      <p class="f-heading">Max score</p>
                    </div>
                  </div>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">1.</p>
                          <p class="mb-0 ms-3" v-if="enableEditForm == false">
                            {{ updateItem.option_1_name }}
                          </p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="updateItem.option_1_name"
                            name="option_1_name"
                            v-if="enableEditForm == true"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_1_min }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_1_min"
                          v-if="enableEditForm == true"
                          name="option_1_min"
                        />
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_1_max || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_1_max"
                          v-if="enableEditForm == true"
                          name="option_1_min"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none" v-if="updateItem.option_2_name">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">2.</p>
                          <p class="mb-0 ms-3" v-if="enableEditForm == false">
                            {{ updateItem.option_2_name || "-" }}
                          </p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="updateItem.option_2_name"
                            name="option_2_name"
                            v-if="enableEditForm == true"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_2_min || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_2_min"
                          name="option_2_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_2_max || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_2_max"
                          name="option_2_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">3.</p>
                          <p class="mb-0 ms-3" v-if="enableEditForm == false">
                            {{ updateItem.option_3_name || "-" }}
                          </p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="updateItem.option_3_name"
                            name="option_3_name"
                            v-if="enableEditForm == true"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_3_min || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_3_min"
                          name="option_3_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_3_max || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_3_max"
                          name="option_3_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">4.</p>
                          <p class="mb-0 ms-3" v-if="enableEditForm == false">
                            {{ updateItem.option_4_name || "-" }}
                          </p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="updateItem.option_4_name"
                            name="option_4_name"
                            v-if="enableEditForm == true"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_4_min || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_4_min"
                          name="option_4_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_4_max || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_4_max"
                          name="option_4_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">5.</p>
                          <p class="mb-0 ms-3" v-if="enableEditForm == false">
                            {{ updateItem.option_5_name || "-" }}
                          </p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="updateItem.option_5_name"
                            name="option_5_name"
                            v-if="enableEditForm == true"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_5_min || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_5_min"
                          name="option_5_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_5_max || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_5_max"
                          name="option_5_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="shadow-none">
                    <div class="row mb-3">
                      <div class="col-8">
                        <d class="d-flex align-items-center">
                          <p class="mb-0">6.</p>
                          <p class="mb-0 ms-3" v-if="enableEditForm == false">
                            {{ updateItem.option_6_name || "-" }}
                          </p>
                          <input
                            type="text"
                            class="ms-2 form-control"
                            v-model="updateItem.option_6_name"
                            name="option_6_name"
                            v-if="enableEditForm == true"
                          />
                        </d>
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_6_min || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_6_min"
                          name="option_6_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                      <div class="col-2">
                        <p
                          class="mb-0 text-center"
                          v-if="enableEditForm == false"
                        >
                          {{ updateItem.option_6_max || "-" }}
                        </p>
                        <input
                          type="number"
                          class="form-control"
                          v-model="updateItem.option_6_max"
                          name="option_6_min"
                          v-if="enableEditForm == true"
                        />
                      </div>
                    </div>
                  </form>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 col-lg-2">
                      <p class="f-heading">Additional name</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <p class="mb-0 ms-3" v-if="enableEditForm == false">
                        {{ updateItem.additional_name || "-" }}
                      </p>
                      <input
                        type="text"
                        class="form-control"
                        v-model="updateItem.additional_name"
                        v-if="enableEditForm == true"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer text-end" v-if="enableEditForm == true">
                  <button class="btn btn-primary" @click="updateFormItem">
                    Update <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- ANCHOR Toast -->
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="dangerToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-danger">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal, Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import axios from "axios";

export default {
  name: "ViewForm",
  components: {
    Sidebar,
  },
  data() {
    return {
      itemID: this.$route.params.id,
      enableEditForm: false,
      currentWeightage: 0,
      calculatedWeightage: this.currentWeightage,
      loading: false,
      disabledAdd: false,
      toastMsg: "",
      updateItem: {},
    };
  },
  filters: {
    category: function (c) {
      switch (c) {
        case "PRODUCT":
          return "Product Maturity";
          break;
        case "COMPETITIVENESS":
          return "Competitiveness";
          break;
        case "INNOVATION":
          return "Innocation";
          break;
        case "FINANCIAL":
          return "Financial Capability";
          break;
        case "MANAGEMENT":
          return "Management Capability";
          break;
      }
    },
    calcWeightage: function (w) {
      return (w * 100).toFixed(0);
    },
  },
  mounted() {
    this.updateItem = this.getFormItemID(parseInt(this.itemID));
    this.updateItem.weightage = this.updateItem.weightage * 100;
    this.$store.dispatch("loadFormList");
    this.calculateWeightage();
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.dangerToast = new Toast(document.getElementById("dangerToast"), {
      delay: 5000,
      autohide: true,
    });
  },
  computed: {
    ...mapGetters(["getFormItemID"]),
    /** Call state form */
    ...mapState(["formItems"]),

    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    currentRole() {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    calculateWeightage() {
      for (let i = 0; i < this.formItems.length; i++) {
        this.currentWeightage += this.formItems[i].weightage;
      }
    },
    resetForm() {
      this.updateItem = this.getFormItemID(parseInt(this.itemID));
      this.updateItem.weightage = this.updateItem.weightage * 100;
      this.enableEditForm = false;
    },
    async updateFormItem() {
      console.log("Updating...");
      this.loading = true;
      this.updateItem.weightage = this.updateItem.weightage / 100;

      try {
        const response = await axios.post(
          `admin/form-item/update/${this.itemID}`,
          this.updateItem,

          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );
        this.toastMsg = response.data.msg;
        this.successToast.show();
        this.loading = false;
        this.enableEditForm = false;
        console.log("Error: " + response);
      } catch (error) {
        if (error.response) {
          this.toastMsg = error.response.data.error;
          this.dangerToast.show();
          this.loading = false;
        } else {
          this.toastMsg =
            "Something went wrong while updating. Please try again.";
          this.dangerToast.show();
        }
        this.loading = false;
      }
      this.scrollToTop();
    },
  },
};
</script>