<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row mb-4">
            <div class="col-12">
              <h2>Login & Security</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-10 col-xl-8">
              <div class="card dashboard-cards">
                <div class="card-header">
                  <h5>Change your password</h5>
                </div>
                <div class="card-body">
                  <form
                    class="shadow-none mx-auto"
                    @submit.prevent="handleSubmit"
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <!-- Form Group -->
                        <div class="form-group">
                          <label for="password" class="input-label"
                            >Password</label
                          >
                          <div class="input-group has-validation">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              class="form-control"
                              v-model="password"
                            />
                          </div>
                        </div>
                        <!-- End Form Group -->
                      </div>

                      <div class="col-sm-12">
                        <!-- Form Group -->
                        <div class="form-group mt-4">
                          <label for="password_confirmation" class="input-label"
                            >Confirm password</label
                          >
                          <input
                            type="password"
                            name="password_confirmation"
                            id="password_confirmation"
                            class="form-control"
                            v-model="confirmPassword"
                          />
                        </div>
                        <!-- End Form Group -->
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-5">
                      <button class="btn btn-outline-primary" type="reset">
                        Cancel
                      </button>

                      <button type="submit" class="btn btn-primary ms-3">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
    <!-- ANCHOR Success Modal -->
    <div
      class="modal fade"
      id="successModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <h5 class="modal-title text-white my-auto" id="successModalLabel">Hooray!</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body"><p class="text-white mb-0">{{ successMsg }}</p></div>
        </div>
      </div>
    </div>

    <!-- ANCHOR Fail Modal -->
    <div
      class="modal fade"
      id="failModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="failModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content border-0">
          <div class="modal-header border-0 bg-danger">
            <h5 class="modal-title text-white ms-auto" id="failModalLabel">
              Oops!
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-white text-center">
            <p class="mb-0">{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import Header from "../../components/Dashboard/Header";
import axios from "axios";
import { Modal } from "bootstrap";

export default {
  name: "Security",
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorMsg:
        "There was an error while changing your password. Please try again.",
      successMsg: "You have successfully changed you password.",
    };
  },
  components: {
    Sidebar,
    Header,
  },
  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
  },
  mounted() {
    this.modalFail = new Modal(document.getElementById("failModal"));
    this.modalSuccess = new Modal(document.getElementById("successModal"));
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post("auth/password/reset", {
          password: this.password,
          confirmPassword: this.confirmPassword,
        });
        // console.log("Response: " + JSON.stringify(response.data.msg))
        // this.$store.commit("setCompanies", response.data.companyList);
        /** Get new company list */
        this.modalSuccess.show();
      } catch (error) {
        this.modalFail.show();
        console.log("Error: " + error);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
button.btn-close {
  position: absolute;
  right: 20px;
}
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>