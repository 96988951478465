<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <div class="card dashboard-cards">
            <div class="card-header">
              <h4>Upgrade to PRO!</h4>
            </div>
            <div class="card-body">
              
          <form class="shadow-none">
            <div class="row">
              <div class="col-12 col-sm-5"><p>Upload proof of payment:</p></div>
              <div class="col-12 col-sm-7">
                <div class="js-form-message form-group">
                  <input
                    type="file"
                    name="proof"
                    class="form-control"
                    @change="handleFileSelected"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 col-sm-5"><p>Payer name:</p></div>
              <div class="col-12 col-sm-7">
                <div class="js-form-message form-group">
                  <input
                    type="text"
                    name="proof"
                    v-model="name"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="submit-form mt-4 text-end">
              <button
                class="btn btn-primary ms-auto"
                type="submit"
                @click.prevent="handleSubmit"
              >
                Submit
              </button>
            </div>
          </form>
            </div>
          </div>
            </div>
          </div>

          <!-- ANCHOR Success Modal -->
          <div
            class="modal fade"
            id="successModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="successModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content bg-primary">
                <div class="modal-header border-0">
                  <h5
                    class="modal-title text-white mx-auto"
                    id="successModalLabel"
                  >
                    Hooray!
                  </h5>
                </div>
                <div class="modal-body text-white text-center">
                  {{ successMsg }}
                </div>
              </div>
            </div>
          </div>

          <!-- ANCHOR Fail Modal -->
          <div
            class="modal fade"
            id="failModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="failModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content border-0">
                <div class="modal-header border-0">
                  <h5
                    class="modal-title ms-auto text-danger"
                    id="failModalLabel"
                  >
                    Oops!
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body text-white text-center">
                  <p class="">{{ errorMsg }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal } from "bootstrap";
import axios from "axios";
export default {
  name: "Upgrade",
  data() {
    return {
      file: null,
      name: "",
      errorMsg: "",
      successMsg: "",
    };
  },
  components: {
    Sidebar,
  },
  mounted() {
    this.modalFail = new Modal(document.getElementById("failModal"));
    this.modalSuccess = new Modal(document.getElementById("successModal"), {
      keyboard: false,
      backdrop: "static",
    });
  },
  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
  },
  methods: {
    handleFileSelected(event) {
      // console.log(event);
      this.file = event.target.files[0];
    },
    routeFunction() {
      this.modalSuccess.hide();
      this.$router.push({ name: "Dashboard" });
    },
    async handleSubmit() {
      let formData = new FormData();
      formData.append("payment_ref", this.file);
      // console.log("form data: " + formData);
      const response = await axios.post(
        "user/upgrade-upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
            "Content-Type":
              "multipart/form-data; boundary=---------------------------974767299852498929531610575",
          },
        }
      );
      console.log(response);
      this.successMsg =
        "You have successfully upgraded your membership. We will direct you in a bit";
      this.modalSuccess.show();
      setTimeout(() => this.routeFunction(), 3000);
      // try {

      // } catch (error) {
      //   this.errorMsg = 'We were unable to process your submission. please try again later or contact our customer services.'
      //   this.modalFail.show()
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>