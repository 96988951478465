<template>
  <div>
    <div class="section fullscreen">
      <div class="container">
        <div class="row justify-content-around align-items-center">
          <div class="col-12 col-lg-4">
            <p class="fc-primary f-heading">CocoonX <sup>&#174;</sup></p>
            <ul class="list-unstyled contact-list">
              <li>
                <i class="bi bi-geo-alt fc-primary"></i>
                <p class="">
                  Block B, SME 1, Platinum Sentral, <br />
                  Jalan Stesen Sentral 2, Kuala Lumpur Sentral, <br />
                  50470 Kuala Lumpur
                </p>
              </li>
              <li>
                <i class="bi bi-mailbox fc-primary"></i>
                <p>
                  <a href="mailto:customer-service@cocoonx.com"
                    ><u>customer-service@cocoonx.com</u></a
                  >
                </p>
              </li>
              <li>
                <i class="bi bi-phone fc-primary"></i>
                <p>
                  <a href="tel:0312345678"><u>03-12345678</u></a>
                </p>
              </li>
            </ul>
            <img
              src="@/assets/img/contact-us.png"
              alt="working"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-lg-6">
            <!-- <img
            src="@/assets/img/envalopes.png"
            alt="office"
            class="img-fluid contact-image"
          /> -->
            <div class="card hero-form mx-md-5">
              <div class="card-header text-center py-4 px-3 px-md-6 border-0">
                <h5 class="mb-0">Send us an enquiry</h5>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label class="input-label">Name</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      v-model="name"
                    />
                  </div>
                  <div class="form-group">
                    <label class="input-label">Email address</label>

                    <input
                      type="email"
                      class="form-control"
                      name="emailAddress"
                      id="emailAddress"
                      v-model="email"
                    />
                  </div>
                  <div class="form-group">
                    <label class="input-label">Mobile number</label>
                    <input
                      type="number"
                      class="form-control mb-3"
                      v-model="mobile"
                    />
                  </div>
                  <div class="form-group">
                    <label class="input-label">Enquiry</label>
                    <textarea
                      name="enquiry"
                      cols="30"
                      rows="3"
                      class="form-control mb-4"
                      v-model="enquiry"
                    ></textarea>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-primary transition-3d-hover px-5"
                      type="submit"
                    >
                      Send enquiry
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      enquiry: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.contact-list {
  li {
    display: flex;
    align-items: flex-start;
    p {
      padding-left: 20px;
    }
  }
}
form {
  box-shadow: none;
}
// input {
//   border-radius: 0;
//   border: 0;
//   border-bottom: 1px solid rgba(48, 90, 217, 0.58);
//   font-family: "Rubik", sans-serif;
//   font-size: 14px;
//   &:focus {
//     box-shadow: none;
//   }
// }
// textarea {
//   border-color: rgba(48, 90, 217, 0.58);
//   font-family: "Rubik", sans-serif;
//   font-size: 14px;
// }
// .contact-form-wrapper {
//   // border-radius: 0;
//   border: 1px solid rgba(48, 90, 217, 0.58);
//   box-shadow: none;
//   padding: 20px;
//   @media only screen and (min-width: 1200px) {
//     padding: 20px 40px;
//   }
// }
// .section {
//   padding: 40px 0;
//   @media only screen and (min-width: 1200px) {
//     padding: 60px 0;
//   }
// }
</style>