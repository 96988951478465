<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
              <div class="card dashboard-cards">
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <button
                      class="btn btn-primary transition-3d-hover"
                      @click="goBack()"
                    >
                      <i class="bi bi-arrow-left"></i> Back
                    </button>
                    <div class="edit-btns">
                      <button
                        class="btn btn-outline-primary"
                        @click="enableEditForm = true"
                        v-if="enableEditForm == false && currentRole == 'ADMIN'"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-outline-danger"
                        @click.prevent="enableEditForm = false"
                        v-if="enableEditForm == true"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Program name</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="program.name"
                        v-if="enableEditForm == true"
                      />
                      <p v-if="enableEditForm == false">{{ program.name }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Start date</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="datetime-local"
                        class="form-control"
                        v-model="program.start_date_time"
                        v-if="enableEditForm == true"
                      />
                      <p v-if="enableEditForm == false">
                        {{ program.start_date_time | moment }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">End date</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="datetime-local"
                        class="form-control"
                        v-model="program.end_date_time"
                        v-if="enableEditForm == true"
                      />
                      <p v-if="enableEditForm == false">
                        {{ program.end_date_time | moment }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Maximum number of company</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="program.total_company_limit"
                        v-if="enableEditForm == true"
                      />
                      <p v-if="enableEditForm == false">
                        {{ program.total_company_limit }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Maximum number of member</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="program.user_company_limit"
                        v-if="enableEditForm == true"
                      />
                      <p v-if="enableEditForm == false">
                        {{ program.user_company_limit }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-footer" v-if="enableEditForm == true">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-primary transition-3d-hover"
                      @click="updateProgram"
                    >
                      Update program
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Toast -->
          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="failToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-danger">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { mapGetters } from "vuex";
import { Toast } from "bootstrap";
import moment from "moment";
import axios from "axios";
export default {
  name: "ViewProgramSingle",
  components: {
    Sidebar,
  },
  data() {
    return {
      id: this.$route.params.id,
      name: this.$route.params.name,
      program: [],
      enableEditForm: false,
      toastMsg: "",
    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMM YYYY, h:mm:ss a");
    },
  },
  computed: {
    ...mapGetters(["getProgramID"]),
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    currentRole() {
      return this.$store.getters.getRole;
    },
  },
  mounted() {
    this.$store.dispatch("loadPrograms");
    this.program = this.getProgramID(parseInt(this.id));
    this.failToast = new Toast(document.getElementById("failToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async updateProgram() {
      try {
        const response = await axios.post(
          `admin/program/update/${this.id}`,
          {
            name: this.program.name,
            start_date_time: this.program.start_date_time,
            end_date_time: this.program.end_date_time,
            total_company_limit: this.program.total_company_limit,
            user_company_limit: this.program.user_company_limit,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );
        this.enableEditForm = false;
        this.$store.dispatch("loadProgramList");
        this.toastMsg = response.data.msg;
        this.successToast.show();
      } catch (error) {
        if (error.response) {
          let status = error.response.status;
          if (status == 500) {
            this.toastMsg = "Something went wrong. Please try again later.";
          } else {
            this.toastMsg = error.response.data.error;
          }
        } else {
          // console.log(error);
          this.toastMsg = "Enable to connect to server";
        }
        this.failToast.show();
        this.scrollToTop();
      }
    },
  },
};
</script>