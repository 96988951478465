<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          
          <ViewCompany />

          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import ViewCompany from "../../components/Dashboard/ViewCompany"
import { mapState } from "vuex";
export default {
  name: "Companies",
  components: {
    Sidebar,
    ViewCompany
  },
  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

    /** Call state profile */
    profile() {
      return this.$store.getters.getCurrentProfile;
    },

    /** Call state companies */
    ...mapState(["companies"]),
  },
};
</script>


<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>