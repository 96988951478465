<template>
  <div class="section fullscreen">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10 col-md-8">
          <form
            class="js-validate card hero-form mx-auto"
            novalidate="novalidate"
            @submit.prevent="handleSubmit"
          >
            <div class="card-header text-center py-4 px-3 px-md-6">
              <h5 class="mb-0">Verify your email</h5>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <!-- Form Group -->
                  <div class="js-form-message form-group">
                    <label for="emailAddress" class="input-label"
                      >Email address</label
                    >
                    <div class="input-group has-validation">
                      <input
                        type="email"
                        :class="'form-control ' + [errorMsg ? isValid : '']"
                        name="emailAddress"
                        id="emailAddress"
                        required
                        data-msg="Please enter a valid email address"
                        v-model="email"
                        v-on:keyup="inputEmail"
                      />
                      <div
                        id="emailAddress"
                        class="invalid-feedback"
                        v-if="errorMsg"
                      >
                        {{ errorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Form Group -->
              </div>
              <div class="input-group has-validation"></div>

              <button
                type="submit"
                class="btn btn-block btn-primary transition-3d-hover d-flex align-items-center justify-content-center"
                :disabled="invalidEmail"
              >
                <span class="me-3">Send me a new link</span>
                <i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ConfirmEmail",
  data() {
    return {
      email: "",
      invalidEmail: true,
      isValid: "",
      errorMsg: "",
    };
  },
  methods: {
    checkEmail(e) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(e);
    },
    inputEmail(e) {
      this.isValid = "is-invalid";
      if (this.checkEmail(this.email)) {
        this.invalidEmail = false;
        this.isValid = "";
      }
    },
    async handleSubmit() {
      try {
        const response = await axios.post("auth/resend", {
          email: this.email,
        });
        console.log(response.data);
      } catch (error) {
        this.isValid = "is-invalid";
        if (error.response.status !== 500) {
          this.errorMsg = error.response.data.error;
          console.log(error.response.data);
          console.log(error.response.status);
        } else {
          this.errorMsg =
            "Opps something went wrong. Please count to 10 and try again.";
        }
        // appropriately handle the error
      }
    },
  },
};
</script>