<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <div class="card dashboard-cards">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <button
                  class="btn btn-primary transition-3d-hover"
                  @click="goBack()"
                >
                  <i class="bi bi-arrow-left"></i> Back
                </button>
                <div class="edit-btns">
                  <button
                    class="btn btn-outline-primary"
                    @click="enableEditForm = true"
                    v-if="enableEditForm == false && currentRole != 'ADMIN'"
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-outline-danger"
                    @click.prevent="
                      resetCompany;
                      enableEditForm = false;
                    "
                    v-if="enableEditForm == true"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <!-- ANCHOR - START: Content -->

              <form
                class="updateCompanyForm shadow-none mt-0"
                novalidate="novalidate"
              >
                <div class="form-body">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3">
                      <!-- ANCHOR Form: company name -->
                      <div class="form-group">
                        <p class="f-heading">Company name</p>
                        <div class="input-group has-validation">
                          <p v-if="enableEditForm == false">
                            {{ thisComp.name }}
                          </p>
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            id="name"
                            required
                            v-model="thisComp.name"
                            v-if="enableEditForm == true"
                            :disabled="enableEditForm == false"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- ANCHOR Form: logo -->
                    <div class="col-12 col-sm-6 mb-3">
                      <div class="js-form-message form-group">
                        <p class="f-heading">Logo</p>
                        <div class="input-group has-validation">
                          <img
                            :src="'storage/' + [thisComp.logo]"
                            :alt="thisComp.logo"
                            class="rounded-circle"
                            v-if="thisComp.logo"
                          />
                          <input
                            type="file"
                            class="form-control"
                            name="logo"
                            id="logo"
                            v-if="!thisComp.logo"
                            @change="selectLogo"
                            :disabled="enableEditForm == false"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- ANCHOR Form: ROC number -->
                    <div class="col-12 col-sm-6 mb-3">
                      <div class="js-form-message form-group">
                        <p class="f-heading">ROC/ROB Number</p>
                        <div class="input-group has-validation">
                          <p v-if="enableEditForm == false">
                            {{ thisComp.roc_no || "-" }}
                          </p>
                          <input
                            type="name"
                            class="form-control"
                            name="roc_rob_no"
                            id="roc_rob_no"
                            required
                            v-model="thisComp.roc_no"
                            v-if="enableEditForm == true"
                            :disabled="enableEditForm == false"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- ANCHOR Form: website -->
                    <div class="col-12 col-sm-6 mb-3">
                      <div class="js-form-message form-group">
                        <p class="f-heading">Website</p>
                        <div class="input-group has-validation">
                          <p v-if="enableEditForm == false">
                            {{ thisComp.website || "-" }}
                          </p>
                          <input
                            type="text"
                            class="form-control"
                            name="companyWebsite"
                            id="companyWebsite"
                            v-model="thisComp.website"
                            v-if="enableEditForm == true"
                            :disabled="enableEditForm == false"
                          />
                        </div>
                      </div>
                      <!-- End Form Group -->
                    </div>
                  </div>

                  <div class="row">
                    <!-- ANCHOR Form: contact person -->
                    <div class="col-12 col-sm-6 mb-3">
                      <div class="js-form-message form-group">
                        <p class="f-heading">Contact Person</p>
                        <div class="input-group has-validation">
                          <p v-if="enableEditForm == false">
                            {{ thisComp.contact_person || "-" }}
                          </p>
                          <input
                            type="contactPerson"
                            class="form-control"
                            name="contactPerson"
                            id="contactPerson"
                            required
                            v-model="thisComp.contact_person"
                            v-if="enableEditForm == true"
                            :disabled="enableEditForm == false"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- ANCHOR Form: contact no -->
                    <div class="col-12 col-sm-6 mb-3">
                      <div class="js-form-message form-group">
                        <p class="f-heading">
                          Contact Number <small>(mobile)</small>
                        </p>
                        <div class="input-group has-validation">
                          <p v-if="enableEditForm == false">
                            {{ thisComp.contact_no || "-" }}
                          </p>
                          <input
                            type="text"
                            class="form-control"
                            name="contact_no"
                            id="contact_no"
                            required
                            v-model="thisComp.contact_no"
                            v-if="enableEditForm == true"
                            :disabled="enableEditForm == false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <!-- ANCHOR Form: years in operation -->
                    <div class="col-12 col-md-6 mb-3">
                      <p class="f-heading">Years in operation:</p>
                      <p v-if="enableEditForm == false">
                        {{ thisComp.operation_year | operation }}
                      </p>
                      <div class="form-check" v-if="enableEditForm == true">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="yearOperated1"
                          name="yearOperated"
                          value="1_YEAR"
                          v-model="thisComp.operation_year"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="yearOperated1"
                          >Less than 1 year</label
                        >
                      </div>
                      <div class="form-check" v-if="enableEditForm == true">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="yearOperated2"
                          name="yearOperated"
                          value="2_YEAR"
                          v-model="thisComp.operation_year"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="yearOperated2"
                          >Less than 2 years</label
                        >
                      </div>
                      <div
                        class="form-check mb-3"
                        v-if="enableEditForm == true"
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          id="yearOperated3"
                          name="yearOperated"
                          value="MORE"
                          v-model="thisComp.operation_year"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="yearOperated3"
                          >More than 2 years</label
                        >
                      </div>
                    </div>

                    <!-- ANCHOR Form: company address -->
                    <div class="col-12 col-md-6 mb-3">
                      <p class="f-heading">Company's address</p>
                      <p v-if="enableEditForm == false">
                        {{ thisComp.address }}
                      </p>
                      <textarea
                        class="form-control mb-3"
                        id="companyAddress"
                        rows="5"
                        v-model="thisComp.address"
                        :disabled="enableEditForm == false"
                        v-if="enableEditForm == true"
                      ></textarea>
                    </div>
                  </div>

                  <!-- ANCHOR Form: industry group -->
                  <div class="row mt-2 mb-3">
                    <p class="f-heading">Industry group:</p>
                    <p v-if="enableEditForm == false">
                      {{ thisComp.industry | industry }}
                    </p>
                    <div class="form-goup" v-if="enableEditForm == true">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry1"
                          value="ICT_HARDWEAR"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="inlineRadio1"
                          >ICT <small>(Hardware)</small></label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry2"
                          value="FINTECT"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="inlineRadio2"
                          >Fintect</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry3"
                          value="MANUFACTURING"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="industry3"
                          >Manufacturing</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry4"
                          value="ICT_SERVICE"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="inlineRadio1"
                          >ICT <small>(Service)</small></label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry5"
                          value="DRONE"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="inlineRadio2"
                          >Drone</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry6"
                          value="ARTIFICIAL_INTELLIGENCE"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="industry3"
                          >Artificial Intelligence</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry7"
                          value="AGRICULTURE"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="industry7"
                          >Agriculture</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry8"
                          v-model="thisComp.industry"
                          value="EDUCATION_TRAINING"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="industry8"
                          >Education & Training</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="industry"
                          id="industry9"
                          value="OTHER"
                          v-model="thisComp.industry"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="industry9"
                          >Other</label
                        >
                      </div>
                    </div>
                    <div class="col-12 col-sm-4" v-if="enableEditForm == true">
                      <div
                        class="js-form-message form-group"
                        v-if="thisComp.industry == 'OTHER'"
                      >
                        <label for="name" class="input-label mb-0"
                          >Define other industry</label
                        >
                        <input
                          type="text"
                          class="form-control other-input"
                          name="industryOther"
                          id="industryOther"
                          v-model="thisComp.industry_other"
                          :disabled="enableEditForm == false"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- ANCHOR Form: total staff -->
                  <div class="row mb-3">
                    <p class="f-heading">Total staff</p>
                    <p v-if="enableEditForm == false">
                      {{ thisComp.staff_no | staff }}
                    </p>
                    <div class="form-group" v-if="enableEditForm == true">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="totalStaff"
                          id="totalStaff"
                          value="5"
                          v-model="thisComp.staff_no"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="totalStaff"
                          >below 5</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="totalStaff"
                          id="totalStaff2"
                          value="10"
                          v-model="thisComp.staff_no"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="totalStaff2"
                          >5 to 10</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="totalStaff"
                          id="totalStaff3"
                          value="MORE"
                          v-model="thisComp.staff_no"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="totalStaff2"
                          >More than 10</label
                        >
                      </div>
                    </div>
                  </div>

                  <!-- ANCHOR Form: business plan readiness -->
                  <div class="row">
                    <p class="f-heading">Business Plan Readiness</p>
                    <p v-if="enableEditForm == false">
                      <span>{{ thisComp.business_plan | plan }}</span>
                      <a
                        :href="
                          'storage/app/' + [thisComp.proof_of_documentation]
                        "
                        class="fc-primary ms-3"
                        download
                        v-if="thisComp.proof_of_documentation"
                        >Download <i class="bi bi-download"></i
                      ></a>
                    </p>
                    <div class="col-12 col-md-7" v-if="enableEditForm == true">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="businessPlan"
                          id="businessPlan1"
                          value="CONCEPT"
                          v-model="thisComp.business_plan"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="businessPlan1">
                          Concept idea
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="businessPlan"
                          id="businessPlan2"
                          value="BASIC"
                          v-model="thisComp.business_plan"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="businessPlan2">
                          Basic business plan <small>(BMC)</small>
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="businessPlan"
                          id="businessPlan3"
                          value="DEFINED"
                          v-model="thisComp.business_plan"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="businessPlan3">
                          Well-defined business plan
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="enableEditForm == true">
                    <div class="col-6 col-md-4">
                      <button
                        class="btn btn-outline-primary"
                        @click.prevent="proofDocument = false"
                        v-if="proofDocument == true"
                      >
                        Upload new
                      </button>
                      <button
                        class="btn btn-outline-danger"
                        @click.prevent="
                          proofDocument = true;
                          thisCompFiles.proof_of_documentation = null;
                        "
                        v-if="proofDocument == false"
                      >
                        Cancel
                      </button>
                    </div>
                    <div class="col-6" v-if="proofDocument == true">
                      <a
                        :href="
                          'storage/app/' + [thisComp.proof_of_documentation]
                        "
                        class="fc-primary"
                        download
                        v-if="thisComp.proof_of_documentation"
                        >Download <i class="bi bi-download"></i
                      ></a>
                    </div>
                    <div class="col-6" v-if="proofDocument == false">
                      <input
                        type="file"
                        class="form-control"
                        name="proof_of_documentation"
                        placeholder="Proof documentation of your product or services"
                        :disabled="enableEditForm == false"
                        @change="selectFileProof"
                        v-if="
                          thisComp.business_plan == 'BASIC' ||
                          thisComp.business_plan == 'DEFINED'
                        "
                      />
                    </div>
                  </div>

                  <!-- ANCHOR Form: business offering -->
                  <div class="row mb-3 mt-3">
                    <p class="f-heading">Business offerings</p>
                    <p v-if="enableEditForm == false">
                      {{ thisComp.business_offering | offer }}
                    </p>
                    <div class="col" v-if="enableEditForm == true">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="businessOffering"
                          id="businessOffering1"
                          value="PRODUCT"
                          v-model="thisComp.business_offering"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="businessOffering1">
                          Product
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="businessOffering"
                          id="businessOffering2"
                          value="SERVICE"
                          v-model="thisComp.business_offering"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="businessOffering2">
                          Services
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="businessOffering"
                          id="businessOffering3"
                          value="BOTH"
                          v-model="thisComp.business_offering"
                          :disabled="enableEditForm == false"
                        />
                        <label class="form-check-label" for="businessOffering3">
                          Both product & services
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- ANCHOR Form: product information -->
                  <p class="f-heading">Product information</p>
                  <div class="product-form-wrap" v-if="enableEditForm == true">
                    <div class="product-list mb-3">
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service name</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <input
                              type="text"
                              class="form-control"
                              name="productInfo1"
                              id="productInfo1"
                              v-model="thisComp.product_1_name"
                              :disabled="enableEditForm == false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service description</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="productDesc1"
                              v-model="thisComp.product_1_description"
                              :disabled="enableEditForm == false"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service proof of documentation</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <input
                              type="file"
                              class="form-control"
                              name="productInfo1"
                              id="productInfo1"
                              @change="selectFileProduct1"
                              :disabled="enableEditForm == false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-end">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-outline-danger mt-1 d-inline"
                          type="button"
                          v-if="showProd2 == 'show' && enableEditForm == true"
                          :disabled="enableEditForm == false"
                          @click="
                            showProd2 = '';
                            thisComp.product_2_name = '';
                            thisComp.product_2_description = '';
                            thisCompFiles.product_2_files = null;
                          "
                        >
                          <span class="me-3">Remove product</span>
                          <i class="bi bi-dash"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-outline-primary mt-1 d-inline ms-3"
                          type="button"
                          v-if="enableEditForm == true"
                          @click="showProd2 = 'show'"
                        >
                          <span class="me-3">Add product</span>
                          <i class="bi bi-plus"></i>
                        </a>
                      </div>
                    </div>

                    <div class="product-list mb-3" v-if="showProd2 == 'show'">
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service name</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <input
                              type="text"
                              class="form-control"
                              name="productInfo1"
                              id="productInfo1"
                              v-model="thisComp.product_2_name"
                              :disabled="enableEditForm == false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service description</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="productDesc1"
                              v-model="thisComp.product_2_description"
                              :disabled="enableEditForm == false"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service proof of documentation</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <input
                              type="file"
                              class="form-control"
                              name="productInfo2"
                              id="productInfo2"
                              @change="selectFileProduct2"
                              :disabled="enableEditForm == false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-outline-danger transition-3d-hover mt-1 d-inline"
                          style="max-width: 200px"
                          type="button"
                          v-if="showProd3 == 'show' && enableEditForm == true"
                          :disabled="enableEditForm == false"
                          @click="
                            showProd3 = '';
                            thisComp.product_3_name = '';
                            thisComp.product_3_description = '';
                            thisCompFiles.product_3_file = null;
                          "
                        >
                          <span class="me-3">Remove product</span>
                          <i class="bi bi-dash"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-outline-primary transition-3d-hover mt-1 d-inline ms-3"
                          style="max-width: 200px"
                          type="button"
                          @click="showProd3 = 'show'"
                          v-if="enableEditForm == true"
                        >
                          <span class="me-3">Add product</span>
                          <i class="bi bi-plus"></i>
                        </a>
                      </div>
                    </div>

                    <div class="product-list mb-3" v-if="showProd3 == 'show'">
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service name</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <input
                              type="text"
                              class="form-control"
                              name="productInfo1"
                              id="productInfo1"
                              v-model="thisComp.productInfo3"
                              :disabled="enableEditForm == false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service description</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="productDesc1"
                              v-model="thisComp.productDesc3"
                              :disabled="enableEditForm == false"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-4">
                          <div class="js-form-message form-group">
                            <label for="name" class="input-label"
                              >Product/Service proof of documentation</label
                            >
                          </div>
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="input-group has-validation">
                            <input
                              type="file"
                              class="form-control"
                              name="productInfo3"
                              id="productInfo3"
                              @change="selectFileProduct3"
                              :disabled="enableEditForm == false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion accordian-custom"
                    id="accordionProductInfo"
                    v-if="enableEditForm == false"
                  >
                    <div class="accordion-item" v-if="thisComp.product_1_name">
                      <h2 class="accordion-header" id="heading-1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-1"
                          aria-expanded="true"
                          aria-controls="collapse-1"
                        >
                          <p class="mb-0">{{ thisComp.product_1_name }}</p>
                        </button>
                      </h2>
                      <div
                        id="collapse-1"
                        class="accordion-collapse collapse show"
                        aria-labelledby="heading-1"
                        data-bs-parent="#accordionProductInfo"
                      >
                        <div class="accordion-body">
                          <p>
                            <a
                              :href="'storage/app/' + [thisComp.product_1_file]"
                              class="fc-primary"
                              download
                              v-if="thisComp.product_1_file"
                              >Download <i class="bi bi-download ms-2"></i
                            ></a>
                          </p>
                          <p>{{ thisComp.product_1_description }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item" v-if="thisComp.product_2_name">
                      <h2 class="accordion-header" id="heading-2">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-2"
                          aria-expanded="false"
                          aria-controls="collapse-2"
                        >
                          <p class="mb-0">{{ thisComp.product_2_name }}</p>
                        </button>
                      </h2>
                      <div
                        id="collapse-2"
                        class="accordion-collapse collapse"
                        aria-labelledby="heading-2"
                        data-bs-parent="#accordionProductInfo"
                      >
                        <div class="accordion-body">
                          <p>
                            <a
                              :href="'storage/app/' + [thisComp.product_2_file]"
                              class="fc-primary"
                              download
                              v-if="thisComp.product_2_file"
                              >Download <i class="bi bi-download ms-2"></i
                            ></a>
                          </p>
                          <p>{{ thisComp.product_2_description }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item" v-if="thisComp.product_3_name">
                      <h2 class="accordion-header" id="heading-3">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-3"
                          aria-expanded="false"
                          aria-controls="collapse-3"
                        >
                          <p class="mb-0">{{ thisComp.product_3_name }}</p>
                        </button>
                      </h2>
                      <div
                        id="collapse-3"
                        class="accordion-collapse collapse"
                        aria-labelledby="heading-3"
                        data-bs-parent="#accordionProductInfo"
                      >
                        <div class="accordion-body">
                          <p>
                            <a
                              :href="'storage/app/' + [thisComp.product_3_file]"
                              class="fc-primary"
                              download
                              v-if="thisComp.product_3_file"
                              >Download <i class="bi bi-download ms-2"></i
                            ></a>
                          </p>
                          <p>{{ thisComp.product_3_description }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <!-- ANCHOR - END: Content -->
            </div>
            <div class="card-footer text-end" v-if="currentRole != 'ADMIN'">
              <button
                class="btn btn-primary transition-3d-hover"
                type="submit"
                @click.prevent="updateCompany(id)"
                :disabled="enableEditForm == false"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ANCHOR Success Modal -->
    <div
      class="modal fade"
      id="successModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content bg-primary">
          <div class="modal-header border-0">
            <h5 class="modal-title text-white mx-auto" id="successModalLabel">
              Hooray!
            </h5>
          </div>
          <div class="modal-body text-white text-center">
            Succesfully updated your company profile
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import ViewCompany from "../../components/Dashboard/ViewCompany";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import axios from "axios";
export default {
  name: "ViewCompanySingle",
  data() {
    return {
      id: this.$route.params.id,
      enableEditForm: false,
      thisComp: [],
      thisCompFiles: {
        logo: null,
        product_1_file: null,
        product_2_file: null,
        product_3_file: null,
        proof_of_documentation: null,
      },
      showProd2: "",
      showProd3: "",
      errorMsg: "",
      isValid: "",
      proofDocument: false,
    };
  },
  components: {
    Sidebar,
    ViewCompany,
  },
  filters: {
    operation: function (year) {
      if (year == "1_YEAR") {
        return "Less than 1 year";
      } else if (year == "2_YEAR") {
        return "Less than 2 years";
      } else if (year == "MORE") {
        return "More than 2 years";
      } else {
        return "-";
      }
    },
    industry: function (ind) {
      switch (ind) {
        case "ICT_SERVICE":
          return "ICT (Service)";
          break;
        case "ICT_HARDWARE":
          return "ICT (Hardware)";
          break;
        case "FINTECT":
          return "Fintect";
          break;
        case "MANUFACTURING":
          return "Manufacturing";
          break;
        case "DRONE":
          return "Drone";
          break;
        case "ARTIFICIAL_INTELLIGENCE":
          return "Artificial Intelligence";
          break;
        case "AGRICULTURE":
          return "Agriculture";
          break;
        case "EDUCATION_TRAINING":
          return "Education & Training";
          break;
        case "OTHER":
          return "Other : " + thisComp.industry_other + "";
          break;
      }
    },
    staff: function (no) {
      switch (no) {
        case "5":
          return "Below 5";
          break;
        case "10":
          return "5 to 10";
          break;
        case "MORE":
          return "More than 10";
          break;
      }
    },
    plan: function (plan) {
      switch (plan) {
        case "BASIC":
          return "Basic business plan (BMC)";
          break;
        case "CONCEPT":
          return "Concept idea";
          break;
        case "DEFINED":
          return "Well-defined business plan";
          break;
      }
    },
    offer: function (offer) {
      switch (offer) {
        case "SERVICE":
          return "Services";
          break;
        case "PRODUCT":
          return "Product";
          break;
        case "BOTH":
          return "Both product & service";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["getCompanyID"]),
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    currentRole() {
      return this.$store.getters.getRole;
    },
  },
  mounted() {
    this.$store.dispatch("loadCompanies");
    this.thisComp = this.getCompanyID(parseInt(this.id));
    this.modalSuccess = new Modal(document.getElementById("successModal"), {
      keyboard: false,
      backdrop: "static",
    });
    this.thisComp.product_2_name && (this.showProd2 = "show");
    this.thisComp.product_3_name && (this.showProd3 = "show");
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    selectLogo(event) {
      console.log(event.target.files[0]);
      this.thisCompFiles.logo = event.target.files[0];
    },
    selectFileProof(event) {
      console.log(event.target.files[0]);
      this.thisCompFiles.proof_of_documentation = event.target.files[0];
    },
    selectFileProduct1(event) {
      console.log(event.target.files[0]);
      this.thisCompFiles.product_1_file = event.target.files[0];
    },
    selectFileProduct2(event) {
      console.log(event.target.files[0]);
      this.thisCompFiles.product_2_file = event.target.files[0];
    },
    selectFileProduct3(event) {
      console.log(event.target.files[0]);
      this.thisCompFiles.product_3_file = event.target.files[0];
    },
    findCompany(id) {
      return this.getCompanyID(parseInt(id));
    },
    resetCompany() {
      console.log("reload companies");
      this.$store.dispatch("loadCompanies");
    },
    async updateCompany(id) {
      let formData = new FormData();
      if (this.thisCompFiles.logo) {
        console.log("Appending logo");
        formData.append("logo", this.thisCompFiles.logo);
      }
      if (this.thisCompFiles.proof_of_documentation) {
        console.log("Proof of documents");
        formData.append(
          "proof_of_documentation",
          this.thisCompFiles.proof_of_documentation
        );
      }
      if (this.thisCompFiles.product_1_file) {
        console.log("Product 1");
        formData.append("product_1_file", this.thisCompFiles.product_1_file);
      }
      if (this.thisCompFiles.product_2_file) {
        console.log("Product 2");
        formData.append("product_2_file", this.thisCompFiles.product_2_file);
      }
      if (this.thisCompFiles.product_3_file) {
        console.log("Product 3");
        formData.append("product_3_file", this.thisCompFiles.product_3_file);
      }

      console.log("Form data " + formData);
      await axios.post(`company/profile/${id}`, this.thisComp, {
        headers: {
          Authorization: `Bearer ${this.currentToken}`,
        },
      });

      await axios.post(`company/profile-docs/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${this.currentToken}`,
        },
      });

      this.resetCompany();
      this.modalSuccess.show();
      setTimeout(() => this.modalSuccess.hide(), 3000);
      this.enableEditForm = false;
      window.scrollTo(0, 0);
    },
  },
};
</script>


<style lang="scss" scoped>
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>