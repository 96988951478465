<template>
  <div>
    <div class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6 text-center">
                <h1>Our latest events</h1>
                <!-- <p>Enjoy discounts to events and meet up with inspiring entrepreneurs</p> -->
                <!-- <img src="@/assets/img/reading.png" alt="community" class="img-fluid mt-5"> -->
            </div>
        </div>
    </div>
</div>
<div class="section bg-primary-color">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-4">
                <form action="" id="search-articles">
                    <div class="input-group mb-5 bg-white">
                        <input type="text" class="form-control" placeholder="Search an article..."
                            aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i
                                class="bi bi-search"></i></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static bg-white">
                        <strong class="d-inline-block mb-2 text-primary">World</strong>
                        <h3 class="mb-0">This is an article</h3>
                        <div class="mb-1 text-muted">Nov 12</div>
                        <p class="card-text mb-auto">This is a wider card with supporting text below as a natural
                            lead-in to additional content.</p>
                        <a href="#" class="stretched-link">Continue reading</a>
                    </div>
                    <div class="col-auto d-none d-lg-block">
                        <div class="img-wrapper">
                            <img src="https://picsum.photos/200/250?random=2" alt="" class="img-fluid">
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static bg-white">
                        <strong class="d-inline-block mb-2 text-primary">World</strong>
                        <h3 class="mb-0">Another article here</h3>
                        <div class="mb-1 text-muted">Nov 12</div>
                        <p class="card-text mb-auto">This is a wider card with supporting text below as a natural
                            lead-in to additional content.</p>
                        <a href="#" class="stretched-link">Continue reading</a>
                    </div>
                    <div class="col-auto d-none d-lg-block">
                        <div class="img-wrapper">
                            <img src="https://picsum.photos/200/250?random=1" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static bg-white">
                        <strong class="d-inline-block mb-2 text-primary">World</strong>
                        <h3 class="mb-0">Third article</h3>
                        <div class="mb-1 text-muted">Nov 12</div>
                        <p class="card-text mb-auto">This is a wider card with supporting text below as a natural
                            lead-in to additional content.</p>
                        <a href="#" class="stretched-link">Continue reading</a>
                    </div>
                    <div class="col-auto d-none d-lg-block">
                        <div class="img-wrapper">
                            <img src="https://picsum.photos/200/250?random=4" alt="" class="img-fluid">
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static bg-white">
                        <strong class="d-inline-block mb-2 text-primary">World</strong>
                        <h3 class="mb-0">There will be more articles</h3>
                        <div class="mb-1 text-muted">Nov 12</div>
                        <p class="card-text mb-auto">This is a wider card with supporting text below as a natural
                            lead-in to additional content.</p>
                        <a href="#" class="stretched-link">Continue reading</a>
                    </div>
                    <div class="col-auto d-none d-lg-block">
                        <div class="img-wrapper">
                            <img src="https://picsum.photos/200/250?random=3" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      <CTA />
  </div>
</template>

<script>
import CTA from '../components/CTA'
export default {
  name: 'Events',
      components: {
    CTA
  },
}
</script>