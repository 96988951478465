<template>
  <div>
    <div class="section hero-banner d-md-flex align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-lg-6">
            <h1 class="mb-4">Build your perfect startup</h1>
            <p class="heading">Whatever your goal - we will get you there.</p>
            <router-link
              :to="{ name: 'Join Community' }"
              class="btn btn-outline-primary transition-3d-hover px-5"
              >Join us</router-link
            >
          </div>
          <div
            class="mt-5 mt-lg-0 col-12 col-lg-6 d-flex justify-content-center"
          >
            <img
              class="img-fluid"
              src="@/assets/img/we-are-in-office-1.svg"
              alt="Image Description"
            />
          </div>
        </div>
      </div>

      <!-- Diagonal block -->
      <div class="slant-container">
        <div class="slanted-to-left bg-primary-color dark"></div>
        <div class="slanted-cover"></div>
      </div>
    </div>

    <div class="section smaller-padding intro bg-primary-color dark">
      <div class="container justify-content-center">
        <div class="intro-wrapper mb-4">
          <div class="copy-wrapper text-center">
            <h3 class="regular text-white">Zero Hassle, More Results</h3>
            <p class="text-white-50">
              We will assist you through our programs to get you on track! Here
              are our models of assistance that will get you up and running.
            </p>
            <router-link
              to="/enroll"
              class="btn btn-outline-primary transition-3d-hover"
            >
              Learn more
            </router-link>
          </div>
          <!-- <img
            class="img-fluid mt-4 mt-md-0"
            src="@/assets/img/team-discuss.png"
            alt="intro-img-framework"
          /> -->
        </div>
        <div class="body">
          <!-- Framework flow -->
          <div class="framework-flow-wrapper">
            <!-- Each framework -->
            <div class="card flow border-0 mx-0 mx-md-3 mx-lg-2">
              <div class="card-body">
                <!-- <div class="card-img-wrapper pb-3">
                  <img
                    src="@/assets/img/recruitment.jpg"
                    class="card-img img-fluid img-center"
                    alt="Illustration"
                  />
                </div> -->
                <h5 class="fs-5 text-dark mb-3">Collaboration</h5>
                <p class="text-dark opacity-6 mb-0">
                  Seek immediate strategic partnership through Joint IP
                  Development, Job Outsourcing or Open Innovation
                </p>
              </div>
            </div>
            <div class="card flow border-0 mx-0 mx-md-3 mx-lg-2">
              <div class="card-body">
                <!-- <div class="card-img-wrapper pb-3">
                  <img
                    src="@/assets/img/incubation.jpg"
                    class="card-img img-fluid img-center"
                    alt="Illustration"
                  />
                </div> -->
                <h5 class="fs-5 text-dark mb-3">Incubator</h5>
                <p class="text-dark opacity-6 mb-0">
                  Grow your business ideation, skills, business planning to
                  kickstart product design and development (TRL 5-6)
                </p>
              </div>
            </div>
            <div class="card flow border-0 mx-0 mx-md-3 mx-lg-2">
              <div class="card-body">
                <!-- <div class="card-img-wrapper pb-3">
                  <img
                    src="@/assets/img/illustration-7.svg"
                    class="card-img img-fluid img-center"
                    alt="Illustration"
                  />
                </div> -->
                <h5 class="fs-5 text-dark mb-3">Accelerator</h5>
                <p class="text-dark opacity-6 mb-0">
                  Fine tune your business plan, product, prototype & gain
                  mentorship and faster got-to-market phase.
                </p>
              </div>
            </div>
            <div class="card flow border-0 mx-0 mx-md-3 mx-lg-2">
              <div class="card-body">
                <!-- <div class="card-img-wrapper pb-3">
                  <img
                    src="@/assets/img/accelerate.jpg"
                    class="card-img img-fluid img-center"
                    alt="Illustration"
                  />
                </div> -->
                <h5 class="fs-5 text-dark mb-3">M&A</h5>
                <p class="text-dark opacity-6 mb-0">
                  Scale up your business and unlock global market access via
                  equity investment, joint venture (TRL>9)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12 col-md-8">
            <h3 class="regular text-white">Let us introduce our partners</h3>
            <p class="text-white-50">
              We are creating and maintaining a unique tech ecosystem of
              cooperating companies and institutions. We want to work closely
              with tech involved and specific industry relevant universities and
              collaborate with all companies and institutions sharing the same
              ideas we have – to empower entrepreneurs
            </p>
          </div>
        </div>
        <div class="partners pt-5">
          <div class="partner-slide">
            <div class="img-wrapper">
              <img
                src="@/assets/img/microsoft_PNG19.png"
                class="img-fluid img-center"
                alt="Microsoft"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/standford-ai.png"
                class="img-fluid img-center"
                alt="Standford AI"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/alibaba.png"
                class="img-fluid img-center"
                alt="Ali baba cloud"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/EDGE196.png"
                class="img-fluid img-center"
                alt="EDGE196"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/rmit.png"
                class="img-fluid img-center"
                alt="RMIT"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/terradrone.png"
                class="img-fluid img-center"
                alt="terradrone"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/hanson-boston.png"
                class="img-fluid img-center"
                alt="hanson-boston"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/inference.png"
                class="img-fluid img-center"
                alt="inference"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/realwear.png"
                class="img-fluid img-center"
                alt="realwear"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/mida.png"
                class="img-fluid img-center"
                alt="mida"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/matrade.png"
                class="img-fluid img-center"
                alt="matrade"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/sarawak-esport.png"
                class="img-fluid img-center"
                alt="sarawak-esport"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/TM.png"
                class="img-fluid img-center"
                alt="TM"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/mdec.png"
                class="img-fluid img-center"
                alt="MDEC"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/sme-corp.png"
                class="img-fluid img-center"
                alt="SME corp"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/tegas.png"
                class="img-fluid img-center"
                alt="Tegas"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/teraju-white.png"
                class="img-fluid img-center"
                alt="Teraju"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/mtdc.png"
                class="img-fluid img-center"
                alt="MTDC"
              />
            </div>
            <div class="img-wrapper">
              <img
                src="@/assets/img/startup-borneo.png"
                class="img-fluid img-center"
                alt="Startup Borneo"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Diagonal block -->
      <!-- <div class="slant-container bottom">
        <div class="slanted-to-right bg-primary-color dark"></div>
      </div> -->
    </div>
    <CTA />
  </div>
</template>

<script>
// @ is an alias to /src
import CTA from "../components/CTA";
import FormRegister from "../components/FormRegister";
import jQuery from "jquery";
import slick from "slick-carousel";
export default {
  name: "Home",
  pageType: "website",
  data() {
    return {
      currentPage: this.$route.name,
    };
  },
  components: {
    CTA,
    FormRegister,
  },
  mounted() {
    jQuery(".partner-slide").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  },
};
</script>
