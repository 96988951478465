<template>
  <div class="cocoonx-dashboard">
    <Header />
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          
          <!-- ANCHOR - START: Content -->
          <AlertUpgrade />
          <!-- ANCHOR - END: Content -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar"
import Header from "../../components/Dashboard/Header"
import AlertUpgrade from "../../components/Dashboard/AlertUpgrade"

export default {
  name: "AddMembers",
    components: {
    Sidebar,
    Header,
    AlertUpgrade
  },
  computed: {
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>