<template>
  <div class="section fullscreen">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
          <FormLogin />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormLogin from "../components/FormLogin";
export default {
  name: "Login",
  components: {
    FormLogin,
  },

};
</script>