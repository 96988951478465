<template>
  <footer class="footer"  v-if="pageType == 'website'">
    <div class="container">
      <div class="top-row d-flex flex-wrap">
        <div class="col-12 col-lg-8">
          <div class="logo-wrapper mb-4 mb-md-0">
            <img
              src="@/assets/img/logo-square.png"
              alt="logo"
              class="img-fluid logo"
            />
            <div class="text-wrapper">
              <p>
                Block B, SME 1, Platinum Sentral, <br />
                Jalan Stesen Sentral 2, Kuala Lumpur Sentral, <br />
                50470 Kuala Lumpur
              </p>
              <div class="social-links">
                <ul class="list-unstyled">
                  <li>
                    <a href="#"><i class="bi bi-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="bi bi-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="col-wrapper">
            <p class="fw-bold">CocoonX</p>
            <div class="page-links">
              <ul class="list-unstyled">
                <li><router-link to="/about">About</router-link></li>
                <li><router-link to="/events">Events</router-link></li>
                <li><router-link to="/articles">Articles</router-link></li>
                <li><router-link to="/contact">Contact</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="col-wrapper">
            <p class="fw-bold">Programme</p>
            <div class="page-links">
              <ul class="list-unstyled">
                <li><router-link to="/enroll">Enroll</router-link></li>
                <li>
                  <router-link to="/join-community"
                    >Join the Community</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-row text-center bg-primary-color">
      <p>© 2021 CocoonX. All rights reserved</p>
    </div>
  </footer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "Footer",
  data() {
    return {};
  },
  computed: {
    profile() {
      return this.$store.getters.getCurrentProfile;
    },
    ...mapFields(["accessToken", "pageType"]),
  },
};
</script>