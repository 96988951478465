<template>
  <div>
    <div class="heading d-flex justify-content-between">
      <h5 style="color: #4661b1">Events</h5>
      <a href="#" class="btn btn-link"
        ><small><u>Read all</u></small></a
      >
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card default-dashboard-card mb-4 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title">Event one</h5>
            <p class="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet
              dolorum harum eius beatae ipsum quod.
            </p>
            <a href="#" class="d-block text-end"
              ><i class="bi bi-arrow-return-right"></i></a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card default-dashboard-card mb-4 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title">Event two</h5>
            <p class="card-text">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui
              libero ab quis. Modi vel animi ducimus debitis qui?
            </p>
             <a href="#" class="d-block text-end"
              ><i class="bi bi-arrow-return-right"></i></a
            >
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card default-dashboard-card mb-4 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title">Third event</h5>
            <p class="card-text">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Temporibus impedit nulla mollitia minima qui ipsa praesentium
              commodi doloremque deserunt beatae.
            </p>
             <a href="#" class="d-block text-end"
              ><i class="bi bi-arrow-return-right"></i></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="slider-auto" id="event-slider">
      <div class="item">
        <div class="card dashboard-cards">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
            <a href="#" class="btn btn-link">Go somewhere</a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="card dashboard-cards">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
            <a href="#" class="btn btn-link">Go somewhere</a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="card dashboard-cards">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
            <a href="#" class="btn btn-link">Go somewhere</a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="card dashboard-cards">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
            <a href="#" class="btn btn-link">Go somewhere</a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="card dashboard-cards">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
            <a href="#" class="btn btn-link">Go somewhere</a>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "EventSlide",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
</style>