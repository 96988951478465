<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row justify-content-end">
            <div class="col-4 text-end">
              <button
                class="btn btn-primary transition-3d-hover ms-auto"
                data-bs-toggle="modal"
                data-bs-target="#addProgramModal"
              >
                Add Program
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="table-responsive mt-4">
                <table class="table table-hover table-borderless white-table-list">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="d-flex">Name</div>
                      </th>
                      <th scope="col" class="text-center only-mobile">
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <span>Starts</span>
                        </div>
                      </th>
                      <th scope="col" class="text-center only-mobile">
                        <div class="d-flex align-items-center">
                          <span>Ends</span>
                        </div>
                      </th>
                      <th scope="col" class="text-center only-mobile">
                        <div class="d-flex align-items-center">
                          <span>Status</span>
                        </div>
                      </th>
                      <th scope="col" class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="program in programs" :key="program.id">
                      <th>{{ program.name }}</th>
                      <td class="text-center only-mobile">
                        {{ program.start_date_time | moment }}
                      </td>

                      <td class="text-start only-mobile">
                        {{ program.end_date_time | moment }}
                      </td>
                      <td class="text-start only-mobile">
                        {{ program.is_delete == 0 ? "Active" : "Deleted" }}
                      </td>
                      <td>
                        <div class="action d-flex justify-content-center">
                          <button
                            class="btn btn-link text-primary text-center py-0"
                            @click="
                              programID = program.id;
                              programName = program.name;
                              viewProgram(program.id, program.name);
                            "
                          >
                            <i class="bi bi-eye"></i>
                          </button>
                          <button
                            :class="
                              'btn btn-link text-center py-0 ' +
                              [
                                program.is_delete == 0
                                  ? 'text-danger'
                                  : 'text-success',
                              ]
                            "
                            data-bs-toggle="modal"
                            data-bs-target="#deleteProgramModal"
                            @click="
                              programID = program.id;
                              programName = program.name;
                              programStatus =
                                program.is_delete == 0 ? 'delete' : 'restore';
                            "
                          >
                            <i
                              :class="
                                'bi ' +
                                [
                                  program.is_delete == 0
                                    ? 'bi-trash'
                                    : 'bi-arrow-counterclockwise',
                                ]
                              "
                            ></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Modal: create program -->
          <div
            class="modal fade viewDetails"
            id="addProgramModal"
            tabindex="-1"
            aria-labelledby="addProgramModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title mx-auto" id="addProgramModalLabel">
                    Add a new program
                  </h5>
                </div>
                <div class="modal-body">
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Name</p>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" v-model="name" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Start date</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="datetime-local"
                        class="form-control"
                        v-model="start"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">End date</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="datetime-local"
                        class="form-control"
                        v-model="end"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Maximum number of company</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="companyLimit"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <p class="f-heading">Maximum number of member</p>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="userLimit"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="createProgram()"
                  >
                    Create program
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal: delete program -->
          <!-- Modal -->
          <div
            class="modal fade viewDetails"
            id="deleteProgramModal"
            tabindex="-1"
            aria-labelledby="deleteProgramModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title text-capitalize"
                    id="deleteProgramModalLabel"
                  >
                    {{ programStatus }} program
                  </h5>
                </div>
                <div class="modal-body">
                  <p>
                    Are you sure you want to <b>{{ programStatus }} </b> this
                    program?
                  </p>
                  <p class="f-heading">{{ programName }}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    :class="
                      'btn text-capitalize ' +
                      [programStatus == 'delete' ? 'btn-danger' : 'btn-primary']
                    "
                    @click="deleteProgram(programID)"
                  >
                    {{ programStatus }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Toast -->
          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal, Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  name: "ViewCompanies",
  components: {
    Sidebar,
  },
  data() {
    return {
      programID: "",
      programName: "",
      programStatus: "",
      name: "",
      start: "",
      end: "",
      companyLimit: "",
      userLimit: "",
      toastMsg: "",
    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMM YYYY, h:mm:ss a");
    },
  },
  mounted() {
    this.$store.dispatch("loadProgramList");
    this.addProgram = new Modal(document.getElementById("addProgramModal"));
    this.deleteProgramModal = new Modal(
      document.getElementById("deleteProgramModal")
    );
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
  },
  computed: {
    /** Call state companies */
    ...mapState(["programs"]),

    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
  },
  methods: {
    validation() {},
    async createProgram() {
      const response = await axios.post(
        "admin/program/create",
        {
          name: this.name,
          start_date_time: this.start,
          end_date_time: this.end,
          total_company_limit: this.companyLimit,
          user_company_limit: this.userLimit,
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.$store.dispatch("loadProgramList");
      this.addProgram.hide();
      this.toastMsg = response.data.msg;
      this.successToast.show();
    },
    viewProgram(id, name) {
      // console.log("ID: " + id);
      // console.log("Name: " + name);
      this.$router.push("/program/" + id + "/" + name + "");
    },
    async deleteProgram(id) {
      const response = await axios.post(
        "admin/program/delete",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );
      this.$store.dispatch("loadProgramList");
      this.toastMsg = response.data.msg;
      this.deleteProgramModal.hide();
      this.primaryToast.show();
    },
  },
};
</script>