<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
              <div class="card dashboard-cards">
                <div class="card-header">
                  <div class="d-flex justify-content-start">
                    <button
                      class="btn btn-primary transition-3d-hover"
                      @click="goBack()"
                    >
                      <i class="bi bi-arrow-left"></i> Back
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row justify-content-center mb-4">
                    <div class="col-12 col-md-3">
                      <img
                        src="@/assets/img/anonymous.jpg"
                        alt="profile picture"
                        class="rounded-circle img-fluid"
                        v-if="!assessor.profile_photo"
                      />
                      <img
                        :src="'/storage/app/' + [assessor.profile_photo]"
                        class="rounded-circle img-fluid"
                        alt="profile_photo"
                        v-if="assessor.profile_photo"
                      />
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Title</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.title || "-" }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Name</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.name }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Gender</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.gender || "-" }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Email</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.email }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">IC/Passport</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.ic_passport_no || "-" }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Nationality</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.nationality || "-" }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Date joined</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.created_at | moment }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                    <div class="col-4">
                      <p class="f-heading">Created by</p>
                    </div>
                    <div class="col-6">
                      <p>: {{ assessor.created_by }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-4">
                      <p class="f-heading mb-0">Contact assessor</p>
                    </div>
                    <div class="col-6">
                      <div class="action d-flex justify-content-start">
                        <a
                          :href="'mailto:' + assessor.email"
                          class="fs-4 fc-primary"
                          ><i class="bi bi-envelope"></i
                        ></a>
                        <a
                          :href="'tel:' + assessor.mobile_no"
                          class="fs-4 fc-primary"
                          ><i class="bi bi-phone-vibrate ms-4"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "ViewAssessorSingle",
  components: {
    Sidebar,
  },
  data() {
    return {
      id: this.$route.params.id,
      name: this.$route.params.name,
      assessor: [],
      enableEditForm: false,
    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMM YYYY");
    },
  },
  computed: {
    ...mapGetters(["getAssessorID"]),
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    currentRole() {
      return this.$store.getters.getRole;
    },
  },
  mounted() {
    this.$store.dispatch("loadAssessorList");
    this.assessor = this.getAssessorID(parseInt(this.id));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>