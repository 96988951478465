<template>
  <div>
    <!-- ANCHOR START component -->
    <div class="program-list-wrapper mt-5">
      <div class="table-responsive">
        <table class="table table-hover table-borderless white-table-list">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" class="text-center">Vacancy</th>
              <th scope="col" class="text-center">Period</th>
              <th scope="col" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody v-for="program in programs" :key="program.id">
            <tr>
              <th>{{ program.name }}</th>
              <td class="text-center">
                {{ program.total_company }}/{{ program.total_company_limit }}
              </td>
              <td class="text-center">
                {{ program.start_date_time | moment }} -
                {{ program.end_date_time | moment }}
              </td>
              <td>
                <div class="action d-flex justify-content-center">
                  <div class="action-edit text-primary">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="
                        programID = program.id;
                        programName = program.name;
                        enrollProgram(program.id);
                      "
                    >
                      Enroll
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- ANCHOR Success Modal -->
    <div
      class="modal fade"
      id="successModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <h5 class="modal-title" id="successModalLabel">Hooray!</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">{{ successMsg }}</div>
        </div>
      </div>
    </div>

    <!-- ANCHOR Fail Modal -->
    <div
      class="modal fade"
      id="failModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="failModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content border-0">
          <div class="modal-header border-0">
            <h5
              class="modal-title ms-auto text-danger"
              id="failModalLabel"
            >
              Oops!
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-white text-center">
            <p class="">{{ errorMsg }}</p>
            <button
              class="btn btn-primary mb-3"
              v-if="role == 'FREE_MEMBER'"
              @click="closeFailModal()"
            >
              Upgrade Now!
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ANCHOR Enroll selection -->
    <div
      class="modal fade"
      id="enrollThis"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog viewDetails">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-auto" id="successModalLabel">Enrollment</h5>
          </div>
          <div class="modal-body text-white">
            <p>Select a company you wish to enroll with this program:</p>
            <h4 class="fc-primary mb-4">{{ programName }}</h4>
            <div>
              <select class="form-select" v-model="companySelected">
                <option
                  v-for="company in companies"
                  :key="company.id"
                  :value="company.id"
                  selected
                >
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-dismiss="modal"
              aria-label="Close">
              Cancel
            </button>
                        <div class="text-end">
              <button
                class="btn btn-primary"
                @click.prevent="handleSubmit"
              >
                Join <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
              <!-- ANCHOR Toast -->
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="dangerToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-danger">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { Modal, Toast } from "bootstrap";
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProgrameList",
  data() {
    return {
      programID: "",
      companySelected: "",
      programName: "",
      errorMsg: "",
      successMsg: "Successfull enrolled",
      loading: false,
      toastMsg: ''
    };
  },
  mounted() {
    this.$store.dispatch("loadPrograms");
    this.$store.dispatch("loadCompanies");
        this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.dangerToast = new Toast(document.getElementById("dangerToast"), {
      delay: 5000,
      autohide: true,
    });
    this.enroll = new Modal(document.getElementById("enrollThis"));
  },
  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    programs() {
      return this.$store.getters.getPrograms;
    },
    ...mapState([ "companies"]),
    ...mapFields(["profile.role"]),
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },

    /** ANCHOR Member filter */
    member: function (role) {
      if (role == "FREE_MEMBER") {
        return "Free Membership";
      } else if (role == "PAID_MEMBER") {
        return "PRO Membership";
      } else if (role == "ASSESSOR") {
        return "Assessor";
      } else {
        return role;
      }
    },
  },
  methods: {
    async getPrograms() {
      const response = await axios.get("program/list", {
        headers: {
          Authorization: `Bearer ${this.currentToken}`,
        },
      });
      console.log("Program list: " + JSON.stringify(response.data.programList));
      this.$store.commit(
        "setPrograms",
        JSON.stringify(response.data.programList)
      );
    },
    enrollProgram(programid) {
      if (this.role == "FREE_MEMBER") {
        this.modalFail.show();
        this.errorMsg =
          "You are not a PRO Member yet! Please upgrade to enroll this program.";
      } else {
        this.enroll.show();
      }
    },
    async handleSubmit() {
      this.loading = true
      try {
        const response = await axios.post(
          "enrollment/join",
          {
            company_id: this.companySelected,
            program_id: this.programID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );
        // this.successMsg = response.data.msg;
        console.log(response);
        this.loading =  false
        this.enroll.hide()
        this.toastMsg = response.data.msg
        this.successToast.show()
      } catch (error) {
        this.loading =  false
        this.enroll.hide()
        this.toastMsg = error.response.data.error
        this.dangerToast.show()
      }
    },
    closeFailModal() {
      this.modalFail.hide();
      this.$router.push({ name: "Upgrade" });
    },
  },
};
</script>