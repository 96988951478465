<template>
  <div>
    <!-- ANCHOR START component -->
    <div class="program-list-wrapper">
      <div class="table-responsive">
        <table class="table table-hover table-borderless white-table-list">
          <thead>
            <tr>
              <th scope="col">Company</th>
              <th scope="col" class="text-center">Program</th>
              <th scope="col" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody v-for="enroll in enrollments" :key="enroll.id">
            <tr>
              <th>{{ enroll.company_name }}</th>
              <td class="text-center">
                {{ enroll.program_name}}
              </td>
              <td>
                <div class="action d-flex justify-content-center">
                  <div class="action-edit text-primary">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="
                        enrollID = enroll.id;
                        viewProgram(enroll.id);
                      "
                    >
                      View
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "ProgrameList",
  data() {
    return {
      enrollID: ''
    };
  },

  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

    ...mapState(["enrollments"]),

  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },

    /** ANCHOR Member filter */
    member: function (role) {
      if (role == "FREE_MEMBER") {
        return "Free Membership";
      } else if (role == "PAID_MEMBER") {
        return "PRO Membership";
      } else if (role == "ASSESSOR") {
        return "Assessor";
      } else {
        return role;
      }
    },
  },
  methods: {
    viewProgram(){
      
    }
  },
};
</script>