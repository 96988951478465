<template>
  <div class="card text-white bg-primary mb-3 border-0 position-relative">
    <!-- <div class="card-header">Header</div> -->
    <div class="card-body">
      <!-- <h5 class="card-title">Primary card title</h5> -->
      <p class="card-text text-white">
        You have no available company to proceed with programme enrollment. Please register your company <router-link :to="{name: 'My Companies'}" class="text-white fw-bold"> <u>here</u> </router-link> 
      </p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AlertAddCompany'
}
</script>