<template>
  <div>
    <div class="section enroll-banner">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-6">
            <img
              src="@/assets/img/enroll.png"
              alt="packup"
              class="img-fluid mb-4 mb-lg-0"
            />
          </div>
          <div class="col-12 col-md-6 text-center">
            <div class="copy-wrapper">
              <h1 class="mb-2">Enroll Our Program</h1>
              <p>
                Start Packing up! Maximise your business and discover your
                potential!
              </p>

              <a
                href="#enroll-start"
                class="btn btn-block btn-outline-primary transition-3d-hover"
              >
                Scroll down <i class="bi bi-chevron-down"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section smaller-padding inner-page" id="enroll-start">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-12 col-md-6">
            <div class="copy-wrapper text-center mt-4 title">
              <h3 class="regular mb-3">
                We have progammes to suit your business goals
              </h3>
              <p>
                Our programs are categorized into two tracks. You will be sorted
                depending on where you are in your business.
              </p>
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-center">
          <div class="col-md-6 col-lg-4 col-12 d-lg-flex align-items-stretch">
            <div class="card enroll border-0">
              <div class="card-body">
                <p class="fs-4 text-center f-heading">Open Innovation</p>
                <img
                  src="@/assets/img/work-together.png"
                  class="card-img-top"
                  alt="open innovation"
                />
                <div class="card-text-wrapper">
                  <div class="card-row">
                    <h6 class="fw-bold">Collaboration</h6>
                    <p class="card-text">
                      Seek immediate strategic partnership through Joint IP
                      Development, Job Outsourcing or Open Innovation.
                    </p>
                  </div>
                  <div class="card-row">
                    <h6 class="fw-bold">Who will enroll in this track?</h6>
                    <ul>
                      <li><p class="mb-0">Business with track record</p></li>
                      <li>
                        <p class="mb-0">
                          Seeking strategic partnership / vendor relationship
                        </p>
                      </li>
                      <li><p class="mb-0">Established core competencies</p></li>
                      <li>
                        <p class="mb-0">Back-to-back payment arrangements</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="card enroll bg-primary-color">
              <div class="card-body">
                <p class="fs-4 f-heading text-center">Startup Growth</p>
                <img
                  src="@/assets/img/startup.png"
                  class="card-img-top"
                  alt="startup growth"
                />

                <div class="program-slide">
                  <div class="card-text-wrapper">
                    <div class="card-row">
                      <h5 class="fw-bold">Incubator</h5>
                      <p class="card-text">
                        Grow your business ideation, skills, business planning
                        to kickstart product design and development (TRL 5-6)
                      </p>
                    </div>
                    <div class="card-row">
                      <h5 class="fw-bold">TRL 5,6</h5>
                      <ul>
                        <li>Business with track record</li>
                        <li>
                          Seeking strategic partnership / vendor relationship
                        </li>
                        <li>Established core competencies</li>
                        <li>Back-to-back payment arrangements</li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-text-wrapper">
                    <div class="card-row">
                      <h5 class="fw-bold">Accelerator</h5>
                      <p class="card-text">
                        Fine tune your business plan, product prototype & gain
                        mentorship and faster go-to-market phase (TRL 7-9)
                      </p>
                    </div>
                    <div class="card-row">
                      <h5 class="fw-bold">TRL 7,8,9</h5>
                      <ul>
                        <li><p class="mb-0">>1 year old startup</p></li>
                        <li><p class="mb-0">Fine tuning business plan</p></li>
                        <li><p class="mb-0">Product prototype v1.0</p></li>
                        <li><p class="mb-0">Under-resourced</p></li>
                        <li><p class="mb-0">Minimal revenue</p></li>
                        <li>
                          <p class="mb-0">Some pitching & PR experience</p>
                        </li>
                        <li>
                          <p class="mb-0">
                            Seeking funds for commercialization
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-text-wrapper">
                    <div class="card-row">
                      <h5 class="fw-bold">M&A</h5>
                      <p class="card-text">
                        Scale up your business and unlock global market access
                        via equity investment, joint venture (TRL >9
                      </p>
                    </div>
                    <div class="card-row">
                      <h5 class="fw-bold">TRL > 9</h5>
                      <ul>
                        <li>Mature business (more than 3 years)</li>
                        <li>Significant revenue</li>
                        <li>Significant net profit</li>
                        <li>Seeking business expansion</li>
                        <li>Access to new market</li>
                        <li>Requires bridging capital for expansion</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CTA />
  </div>
</template>

<script>
import CTA from "../components/CTA";
import jQuery from "jquery";
import slick from "slick-carousel";

export default {
  name: "Enroll",
  components: {
    CTA,
  },
  mounted() {
    jQuery(".program-slide").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
    });
  },
};
</script>

<style lang="scss" scope>
@import "../assets/scss/main.scss";
@import "../assets/scss/pages/enroll.scss";
</style>