<template>
  <div>
    <div class="cocoonx-dashboard">
      <div class="container-fluid body-wrapper">
        <div class="body">
          <Sidebar />
          <div class="body-content flex-grow-1" id="DashboardWrap">
            <!-- ANCHOR - START: Content -->
            <AlertAddCompany v-if="!companies" />
            <div class="d-flex align-items-center">
              <h2 class="mb-0">Programs</h2>
              <button class="btn btn-primary ms-auto" @click="getPrograms()">
                 Refresh programs <i :class="'ms-3 fas fa-sync '+ [loading ? 'fa-spin': '']"></i>
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                <ProgramList />
              </div>
            </div>
            <!-- ANCHOR Toast -->
            <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
              <div
                id="liveToast"
                class="toast hide"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div class="toast-header">
                  <p class="mb-0 text-white">{{ toastMsg }}</p>
                </div>
              </div>
            </div>

            <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
              <div
                id="dangerToast"
                class="toast hide"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div class="toast-header bg-danger">
                  <p class="mb-0 text-white">{{ toastMsg }}</p>
                </div>
              </div>
            </div>

            <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
              <div
                id="successToast"
                class="toast hide"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div class="toast-header bg-success">
                  <p class="mb-0 text-white">{{ toastMsg }}</p>
                </div>
              </div>
            </div>
            <!-- ANCHOR - END: Content -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import AlertAddCompany from "../../components/Dashboard/AlertAddCompany";
import ProgramList from "../../components/Dashboard/ProgramList";
import { Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
export default {
  name: "Programs",
  components: {
    Sidebar,
    AlertAddCompany,
    ProgramList,
  },
  data() {
    return {
      loading: false,
      toastMsg: ''
    }
  },
  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

   /** Call state companies */
    ...mapState(["companies"]),
  },
  mounted() {
    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 2000,
      autohide: true,
    });
    this.dangerToast = new Toast(document.getElementById("dangerToast"), {
      delay: 5000,
      autohide: true,
    });
  },
  methods: {
    getPrograms() {
      this.loading = true
      this.$store.dispatch("loadPrograms");
      this.toastMsg = "Refreshed program list"
      this.successToast.show()
      setTimeout(() => this.loading = false, 1000);
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>