<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->

          <div class="row align-items-stretch">
            <div class="col-12 col-md-6 mb-4">
              <div class="card dashboard-cards">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="details">
                      <h5>Members</h5>
                      <ul class="list-unstyled">
                        <li>
                          <i
                            class="bi bi-circle-fill"
                            style="font-size: 8px; margin-right: 10px"
                          ></i>
                          Free membership
                        </li>
                        <li>
                          <i
                            class="bi bi-circle-fill"
                            style="font-size: 8px; margin-right: 10px"
                          ></i>
                          Paid membership
                        </li>
                      </ul>
                    </div>
                    <div class="chart">
                      <p class="mb-0 fs-4"><i class="bi bi-people"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <div class="card dashboard-cards">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="mb-0">Pending upgrade</h5>
                    <p class="mb-0 fs-4">
                      <i class="bi bi-hourglass-split"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <div class="card dashboard-cards admin-dashboard">
                <div class="card-header">
                  <h5 class="mb-0">Running programs</h5>
                </div>
                <div class="card-body">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col-8">Program name</th>
                        <th scope="col-4 text-end">Ending</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Program 1</td>
                        <td>7 days 13 hours 8 mins</td>
                      </tr>
                      <tr>
                        <td>Program 2</td>
                        <td>7 days 13 hours 8 mins</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="card dashboard-cards admin-dashboard">
                <div class="card-header">
                  <h5 class="mb-0">Assessors progress</h5>
                </div>
                <div class="card-body">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col-8">Name</th>
                        <th scope="col-4 text-end">Pending tasks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Assessor 1</td>
                        <td>1/3</td>
                      </tr>
                      <tr>
                        <td>Assessor 2</td>
                        <td>2/3</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import Header from "../../components/Dashboard/Header";
export default {
  name: "Admin",
  components: {
    Sidebar,
    Header,
  },
  mounted() {
    this.$store.commit("setPageType", 'dashboard')
  }
};
</script>
