<template>
  <div class="cocoonx-dashboard">

    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <h3>Inbox</h3>
          <ul class="list-group list-group-flush mt-4">
            <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div class="msg-title"><p class="mb-0">A message from our customer service</p></div>
                <div class="msg-date text-end"><p class="mb-0"><small>1 hour ago</small></p></div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div class="msg-title"><p class="mb-0">Enrollment status</p></div>
                <div class="msg-date text-end"><p class="mb-0"><small>Yesterday</small></p></div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div class="msg-title"><p class="mb-0">You're a PRO member now!</p></div>
                <div class="msg-date text-end"><p class="mb-0"><small>Mar 24</small></p></div>
              </div>
            </li>
          </ul>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import Header from "../../components/Dashboard/Header";
export default {
  name: "Inbox",
  components: {
    Sidebar,
  },
  computed: {
    /** Call state access_token */
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

    /** Call state profile */
    profile() {
      return this.$store.getters.getCurrentProfile;
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "../../assets/scss/layout/_dashboard.scss";
/**
 * NOTE Write SCSS
 * Styling will only be available on this screen
 * 
 */
</style>