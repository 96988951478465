<template>
  <div class="card card-alert normal" v-if="role == 'FREE_MEMBER'">
    <p class="">
        You are on a free membership. Upgrade your membership now to enjoy all <router-link :to="{name: 'Upgrade'}" class=""> <u>Pro Members</u> </router-link> features!
      </p>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
    name: 'AlertUpgrade',
    computed: {
      ...mapFields(["profile.role"]),
    }
}
</script>