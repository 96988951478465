import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Enroll from '../views/Enroll.vue'
import Events from '../views/Events.vue'
import Articles from '../views/Articles.vue'
import JoinCommunity from '../views/JoinCommunity.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import ConfirmEmail from '../views/ConfirmEmail.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Dashboard from '../views/Members/Dashboard.vue'
import Assessor from '../views/Assessors/Dashboard'
import Admin from '../views/Admin/Dashboard'
import Profile from '../views/Members/Profile'
import Inbox from '../views/Members/Inbox'
import Security from '../views/Members/Security'
import Companies from '../views/Members/Companies'
import AddMembers from '../views/Members/AddMembers'
import Programs from '../views/Members/Programs.vue'
import MyEnrollments from '../views/Members/MyEnrollments.vue'
import Register from '../views/Register.vue' 
import Upgrade from "../views/Members/Upgrade"
import ViewCompanySingle from "../views/Single/ViewCompanySingle"
import ViewMemberSingle from "../views/Single/ViewMemberSingle"
import ViewMembers from "../views/Admin/ViewMembers"
import ViewCompanies from "../views/Admin/ViewCompanies"
import ViewPrograms from "../views/Admin/ViewPrograms"
import ViewProgramSingle from "../views/Single/ViewProgramSingle"
import ViewAssessors from "../views/Admin/ViewAssessors"
import ViewAssessorSingle from "../views/Single/ViewAssessorSingle"
import ViewForm from "../views/Admin/ViewForm"
import ViewFormSingle from "../views/Single/ViewFormSingle"
import ViewAssessments from "../views/Admin/ViewAssessments"

import { mapFields } from "vuex-map-fields";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/enroll',
    name: 'Enroll',
    component: Enroll,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
        meta: {
      type: 'website'
    }
  },
  {
    path: '/join-community',
    name: 'Join Community',
    component: JoinCommunity,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      type: 'website'
    },
    beforeEnter: (to, from, next) => {
      let token = sessionStorage.getItem('cocoonx_access_token')
      if(token) next({name: 'Dashboard'})
      else next()
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/confirmationLink',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      type: 'website'
    }
  },
  {
    path: '/welcome',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/assessor/welcome',
    name: 'Assessor dashboard',
    component: Assessor,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/admin/welcome',
    name: 'Admin dashboard',
    component: Admin,
    meta: {
      type: 'dashboard'
    },
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: Inbox,
        meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/profile',
    name: 'My Profile',
    component: Profile,
        meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/security',
    name: 'Login & Security',
    component: Security,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/companies',
    name: 'My Companies',
    component: Companies,
        meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/add-members',
    name: 'Add Members',
    component: AddMembers,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
        meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/my-enrollments',
    name: 'My Enrollments',
    component: MyEnrollments,
        meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/upgrade-membership',
    name: 'Upgrade',
    component: Upgrade,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/company/:id/:companyname',
    name: 'View Company',
    component: ViewCompanySingle,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/member/:id/:name',
    name: 'View Member',
    component: ViewMemberSingle,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-members',
    name: 'View Members',
    component: ViewMembers,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-companies',
    name: 'View Companies',
    component: ViewCompanies,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-programs',
    name: 'View Programs',
    component: ViewPrograms,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/program/:id/:name',
    name: 'View Program',
    component: ViewProgramSingle,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-assessors',
    name: 'View Assessors',
    component: ViewAssessors,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/assessor/:id/:name',
    name: 'View Assessor',
    component: ViewAssessorSingle,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-form',
    name: 'View Form',
    component: ViewForm,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-form-item/:id',
    name: 'View Form Single',
    component: ViewFormSingle,
    meta: {
      type: 'dashboard'
    }
  },
  {
    path: '/view-assessments',
    name: 'View Assessment',
    component: ViewAssessments,
    meta: {
      type: 'dashboard'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  ...mapFields([
    "profile.access_token",
  ]),
})

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('cocoonx_access_token')
  if (to.meta.type == 'dashboard' && !token) next({ name: 'Login' })
  else next()
})

export default router
