<template>
  <div>
    <div :class="'header-section ' + [pageType == 'dashboard' && 'fixed-top']">
    <div class="top-shortcut bg-primary-color">
      <div :class="'container' + [pageType == 'dashboard' ? '-fluid' : '']">
        <div class="row justify-content-between">
          <div class="col d-flex align-items-center" v-if="pageType == 'dashboard'">
            <!-- Logo -->
            <router-link class="navbar-brand" to="/"
              ><img
                src="@/assets/img/logo-rectangle-white.png"
                alt="logo"
                class="img-fluid"
            /></router-link>
          </div>
          <div
            class="col d-flex d-lg-block align-items-center justify-content-end"
          >
            <ul class="list-unstyled">
              <li v-if="accessToken == null || accessToken == ''">
                <router-link to="/register">Sign Up</router-link>
              </li>

              <li v-if="accessToken == null || accessToken == ''">
                <router-link :to="{name: 'Login'}"
                  >Login <i class="bi bi-person-circle"></i
                ></router-link>
              </li>
              <!-- ANCHOR LINK: Logout -->
              <li v-if="accessToken" :class="[pageType == 'dashboard' ? 'only-mobile ' : '']">
                <a
                  href="javascript:void(0)"
                  v-on:click="
                    logout();
                    closeMenu();
                  "
                  ><span>Sign out</span> <i class="bi bi-box-arrow-right"></i
                ></a>
              </li>
              <li v-if="pageType == 'website' && accessToken" :class="[pageType == 'dashboard' ? 'only-mobile ' : '']">
                <router-link :to="role == 'ADMIN' ? '/admin/dashboard': role == 'ASSESSOR' ? '/assessor/dashboard' : { name: 'Dashboard' }"
                  >Dashboard <i class="bi bi-house-fill"></i
                ></router-link>
              </li>
              <li v-if="pageType == 'dashboard'">
                <!-- offcanvas button -->
                <button
                  class="btnOffCanvas hamburger hamburger--spin"
                  @click="openMenu()"
                  id="menuBtnSidebar"
                >
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="main-menu" v-if="pageType == 'website' || !accessToken">
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <router-link class="navbar-brand" to="/"
            ><img
              src="@/assets/img/logo-rectangle.png"
              alt="logo"
              class="img-fluid"
          /></router-link>
          <button
            class="navbar-toggler hamburger hamburger--spin"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainMenuTrigger"
            aria-controls="mainMenuTrigger"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
          <div class="collapse navbar-collapse" id="mainMenuTrigger">
            <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link active" aria-current="page" to="/"
                  >Home</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about"
                  >About CocoonX</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="/enroll"
                  >Enroll a Program</router-link
                >
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/events">Events</router-link>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link" to="/articles"
                  >Articles</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/join-community"
                  >Join Our Community</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact"
                  >Contact</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="overlay-sidebar" id="overlaySidebar"></div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

import axios from "axios";

export default {
  name: "Navbar",
  data() {
    return {
    }
  },
  watch: {
    $route(to, from) {
      this.$store.commit("setPageType", this.$route.meta.type);
    },
  },
  mounted() {
    this.$store.getters.getRole
    // console.log("Current page type: "+ this.pageType)
  },
  computed: {
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    currentRoute() {
      return this.$route.name;
    },
    pageType() {
      return this.$route.meta.type
    },
    role() {
      return this.$store.getters.getRole
    },
    ...mapFields(["accessToken", "pageType"]),

  },
  methods: {
    openMenu() {
      var btn = document.getElementById("sidebarMainMenu");
      var thisbtn = document.getElementById("menuBtnSidebar");
      var overlaySidebar = document.getElementById("overlaySidebar");
      var body = document.body;
      // console.log("button pushed");

      if (body.classList.contains("menu-is-open")) {
        body.classList.remove("menu-is-open");
      } else {
        body.classList.add("menu-is-open");
      }

      if (thisbtn.classList.contains("is-active")) {
        thisbtn.classList.remove("is-active");
      } else {
        thisbtn.classList.add("is-active");
      }

      if (btn.classList.contains("show")) {
        btn.classList.remove("show");
      } else {
        btn.classList.add("show");
      }

      if (overlaySidebar.classList.contains("show")) {
        overlaySidebar.classList.remove("show");
      } else {
        overlaySidebar.classList.add("show");
      }
    },
    closeMenu() {
      var btn = document.getElementById("sidebarMainMenu");
      var thisbtn = document.getElementById("menuBtnSidebar");
      var overlaySidebar = document.getElementById("overlaySidebar");
      var body = document.body;
      body.classList.remove("menu-is-open");
      thisbtn.classList.remove("is-active");
      btn.classList.remove("show");
      overlaySidebar.classList.remove("show");
    },
    logout() {
      // let token = sessionStorage.getItem("cocoonx_access_token");
      // axios.post("auth/logout", {
      //   access_token: token,
      // });
      // this.$store.commit("clearUser");
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
#overlaySidebar {
  &.show {
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
  }
}
</style>