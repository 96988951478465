<template>
  <div id="componentWrapper">
    <!-- ANCHOR Success message -->
    <div id="successSubmit" class="card hero-form" v-if="successSubmit">
      <div class="card-header py-3">
        <h5 class="mb-0">Congratulations!</h5>
      </div>
      <div class="card-body">
        <p>
          Successfully ssent you an email. Click the link in the email to
          verify.
        </p>
        <p>
          <router-link to="/confirmationLink" class="fc-primary"><u>Click here</u></router-link> if you
          didn't receive an email
        </p>
      </div>
    </div>

    <!-- ANCHOR Error message -->
    <div class="d-block" v-if="errorMsg" id="failSubmit">
      <div
        class="alert position-relative alert-danger alert-dismissible fade show"
        role="alert"
      >
        <strong>Oops!</strong> {{ errorMsg }}
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          @click="dismiss()"
        ></button>
      </div>
    </div>
    <form
      class="js-validate card hero-form"
      novalidate="novalidate"
      @submit.prevent="handleSubmit"
      v-if="!successSubmit"
    >
      <div class="card-header text-center py-4 px-3 px-md-6">
        <h5 class="mb-0" v-if="currentPage == 'Register'">Register</h5>
        <h5 class="mb-0" v-if="currentPage == 'Home'">
          Get exclusive invites to our events!
        </h5>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="name" class="input-label">Name</label>
              <div class="input-group has-validation">
                <input
                  type="name"
                  :class="'form-control ' + [!name ? isValid : '']"
                  name="name"
                  id="name"
                  required
                  v-model="name"
                />
                <div
                  id="registerName"
                  class="invalid-feedback"
                  v-if="errorMsg && !name"
                >
                  Please enter your name
                </div>
              </div>
            </div>
            <!-- End Form Group -->
          </div>

          <div class="col-12 col-md-6 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="email" class="input-label">Email address</label>
              <div class="input-group has-validation">
                <input
                  type="email"
                  :class="'form-control ' + [!validEmail ? isValid : '']"
                  name="email"
                  id="email"
                  required
                  v-model="email"
                />
                <div
                  id="registerEmail"
                  class="invalid-feedback"
                  v-if="errorMsg && !email"
                >
                  Please enter your email
                </div>
                <div
                  id="registerPassword"
                  class="invalid-feedback"
                  v-if="errorMsg && !validEmail"
                >
                  A valid email is required
                </div>
              </div>
            </div>
            <!-- End Form Group -->
          </div>

          <div class="col-12 col-md-6 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="email" class="input-label">Mobile number</label>
              <div class="input-group has-validation">
                <input
                  type="number"
                  class="form-control"
                  name="mobile"
                  id="mobile"
                  required
                  v-model="mobile"
                />
              </div>
            </div>
            <!-- End Form Group -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="password" class="input-label">Password</label>
              <div class="input-group has-validation">
                <input
                  type="password"
                  name="password"
                  id="password"
                  :class="
                    'form-control ' +
                    [!password || !passwordMatch ? isValid : '']
                  "
                  v-model="password"
                />
                <div
                  id="registerPassword"
                  class="invalid-feedback"
                  v-if="errorMsg && !password"
                >
                  Password is required
                </div>
                <div
                  id="registerPasswordConfirm"
                  class="invalid-feedback"
                  v-if="!passwordMatch"
                >
                  Password did not match
                </div>
              </div>
            </div>
            <!-- End Form Group -->
          </div>

          <div class="col-sm-12 col-md-6 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="password_confirmation" class="input-label"
                >Confirm password</label
              >
              <input
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                class="form-control"
                v-model="password_confirmation"
              />
            </div>
            <!-- End Form Group -->
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-block btn-primary transition-3d-hover d-flex align-items-center justify-content-center mt-1"
        >
          <span class="me-3">Register for free</span>
          <i class="bi bi-arrow-right"></i>
        </button>
      </div>
    </form>

    <!-- ANCHOR Success Modal -->
    <div
      class="modal fade"
      id="successModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content bg-primary">
          <div class="modal-header border-0">
            <h5 class="modal-title text-white mx-auto" id="successModalLabel">
              Hooray!
            </h5>
          </div>
          <div class="modal-body text-white text-center">
            You have successfully created an account. Go check your email to get the verification link.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";
export default {
  name: "FormRegister",
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      password: "",
      password_confirmation: "",
      currentPage: this.$route.name,
      errorMsg: "",
      isValid: "",
      passwordMatch: true,
      validEmail: true,
      successSubmit: false,
      currentPage: this.$route.name,
      successMsg: "",
    };
  },
  mounted() {
    this.modalSuccess = new Modal(document.getElementById("successModal"), {
      keyboard: false,
      backdrop: "static",
    });
  },
  methods: {
    dismiss() {
      this.errorMsg = "";
    },
    scrollToTop() {
      var top = document.getElementById("componentWrapper");
      top.scrollIntoView();
    },
    checkEmail(e) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(e);
    },
    routeFunction() {
      this.modalSuccess.hide();
      // this.$router.push({ name: "Login" });
    },
    scroll(id) {
      var elmnt = document.getElementById(id);
      elmnt.scrollIntoView();
    },
    async handleSubmit() {
      // this.validEmail = true;
      // this.passwordMatch = true;
      try {
        const response = await axios.post("auth/register", {
          name: this.name,
          email: this.email,
          mobile_no: this.mobile,
          password: this.password,
          confirmPassword: this.password_confirmation,
        });
        // console.log(response);
        this.successMsg = response.data.msg;
        this.successSubmit = true;
        window.scrollTo(0, 0);
        // this.modalSuccess.show();
        // setTimeout(() => this.routeFunction(), 3000);
        // if (this.currentPage !== "Home") {
        //   this.$router.push({name: 'Login'});
        // } else {
        //   scroll("#successSubmit");
        // }
      } catch (error) {
        if (error.response) {
          let status = error.response.status;
          if (status == 500) {
            this.errorMsg = "Something went wrong. Please try again later.";
          } else {
            this.errorMsg = error.response.data.error;
          }

          this.scrollToTop();
        } else {
          alert("Enable to connect to server");
        }
        // appropriately handle the error
      }
    },
  },
};
</script>