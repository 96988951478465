<template>
  <div class="section fullscreen">
    <div class="container">
      <div class="row justify-content-center">

        <div class="col-12 col-lg-6">
          <FormRegister /> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormRegister from '../components/FormRegister'
export default {
  name: "Register",
  components: {
    FormRegister
  }

};
</script>