<template>
  <div class="cocoonx-dashboard">
    <div class="container-fluid body-wrapper">
      <div class="body">
        <Sidebar />
        <div class="body-content flex-grow-1" id="DashboardWrap">
          <!-- ANCHOR - START: Content -->
          <div class="assessment-list-wrapper">
            <div class="row justify-content-end justify-content-sm-between">
              <div class="col-4">
                <button
                  class="btn btn-link fc-primary mb-3 mb-md-0 ms-auto ms-md-0"
                  @click="refresh"
                >
                  <i class="bi bi-arrow-counterclockwise me-2"></i>
                  <span>Refresh</span>
                </button>
              </div>
              <div class="col-12 col-sm-6">
                <form class="search-bar">
                  <div class="select-group">
                    <select class="form-select" v-model="searchBy">
                      <option selected>Search by..</option>
                      <option value="id">Assessment Id</option>
                      <option value="name">Assessment name</option>
                      <option value="roc_no">ROC No</option>
                    </select>
                  </div>
                  <div class="input-group flex-nowrap">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search assessment"
                      v-model="searchValue"
                    />
                    <span class="input-group-text" id="addon-wrapping">
                      <button
                        class="btn btn-link"
                        type="submit"
                        @click.prevent="searchAssessment"
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>

            <div class="table-responsive mt-4">
              <table class="table table-hover table-borderless white-table-list">
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="d-flex">
                        <button
                          class="btn btn-link p-0 table-sort-btn me-2"
                          @click="
                            sortASC = !sortASC;
                            searchAssessment();
                          "
                        >
                          <i
                            :class="
                              'bi bi-sort-alpha-' + [!sortASC ? 'down' : 'up']
                            "
                          ></i>
                        </button>
                        Name
                      </div>
                    </th>
                    <th scope="col" class="text-center only-mobile">
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <span>Company name</span>
                      </div>
                    </th>
                    <th scope="col" class="text-center only-mobile">
                      <div class="d-flex align-items-center">
                        <span>Status</span>
                      </div>
                    </th>
                    <th scope="col" class="text-center only-mobile">
                      <div class="d-flex align-items-center">
                        <span>Score</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ass in assessments" :key="ass.id">
                    <th>{{ ass.name }}</th>
                    <td class="text-center only-mobile">
                      #{{ ass.user_id }}
                    </td>

                    <td class="text-start only-mobile">
                      {{ ass.roc_no }}
                    </td>
                    <td class="text-start only-mobile">
                      {{ ass.is_delete == 0 ? "Active" : "Deleted" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <nav class="d-flex justify-content-end">
            <ul class="pagination pagination-sm" v-if="totalPages > 2">
              <li
                :class="'page-item ' + [p == currentPage && 'active']"
                v-for="p in totalPages"
                :key="p"
              >
                <a
                  href="javascript:void(0)"
                  class="page-link fc-primary"
                  @click="
                    currentPage = p;
                    searchAssessment();
                  "
                  >{{ p }}</a
                >
              </li>
            </ul>
            <a
              href="javascript:void(0)"
              class="btn btn-link fc-primary ms-auto"
              v-if="totalPages == 2"
              @click="
                currentPage == 2 ? (currentPage = 1) : (currentPage = 2);
                searchAssessment();
              "
              >{{ currentPage == 1 ? "Next" : "Previous" }}</a
            >
          </nav>

          <!--ANCHOR Modal: view member -->
          <div
            class="modal fade viewDetails"
            id="assessmentDetails"
            tabindex="-1"
            aria-labelledby="assessmentDetailsLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="assessmentDetailsLabel">
                    Member Id : #{{ memberSelected.id }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="memberSelected = []"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex justify-content-center">
                    <img
                      :src="'@/assests/storage/' + memberSelected.profile_photo"
                      alt="profile-picture"
                      class="img-circle mx-auto mb-5"
                      v-if="memberSelected.profile_photo"
                    />
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Title</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.title || "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Name</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.name }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Gender</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.gender | gender }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Email</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        <a :href="'mailto:' + memberSelected.email"
                          ><u>{{ memberSelected.email }}</u></a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">IC/Passport</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.ic_passport_no || "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Date joined</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{ memberSelected.created_at | moment }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Email notification</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{
                          memberSelected.email_notification == 0 ? "Off" : "On"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Membership</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{
                          memberSelected.role == "FREE_MEMBER"
                            ? "Free Membership"
                            : memberSelected.role == "PAID_MEMBER"
                            ? "Paid Membership"
                            : "-"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Contact number</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        <a :href="'tel:' + memberSelected.mobile_no"
                          ><u>{{ memberSelected.mobile_no }}</u></a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <p class="f-heading me-3">Status</p>
                    </div>
                    <div class="col-8 col-md-6 col-lg-6">
                      <p>
                        <span class="me-0 me-md-3">:</span>
                        {{
                          memberSelected.is_block == 1
                            ? "Blocked"
                            : memberSelected.is_delete == 1
                            ? "Deleted"
                            : "Active"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ANCHOR Toast -->
          <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="dangerToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-danger">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>

          <div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div
              id="successToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="toast-header bg-success">
                <!-- <i class="bi bi-check2-all me-2"></i> -->
                <p class="mb-0 text-white">{{ toastMsg }}</p>
              </div>
            </div>
          </div>
          <!-- ANCHOR - END: Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Dashboard/Sidebar";
import { Modal, Toast } from "bootstrap";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  name: "ViewAssessments",
  components: {
    Sidebar,
  },
  data() {
    return {
      assessmentID: "",
      memberID: "",
      assessmentName: "",
      searchValue: "",
      assessmentSelected: [],
      memberSelected: [],
      searchedAssessment: [],
      sortASC: false,
      currentPage: 1,
      totalPages: "",
      toastMsg: "",
      limit: "",
      searchBy: "",
    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMMM YYYY");
    },

    /** ANCHOR Member filter */
    member: function (role) {
      if (role == "FREE_MEMBER") {
        return "Free";
      } else if (role == "PAID_MEMBER") {
        return "Paid";
      } else if (role == "ASSESSOR") {
        return "Assessor";
      } else {
        return role;
      }
    },

    gender: function (gender) {
      if (gender == "F") {
        return "Female";
      } else if (gender == "M") {
        return "Male";
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadAssessmentsList");
    this.totalPages = this.page;
    this.assessmentDetailsModal = new Modal(
      document.getElementById("assessmentDetails")
    );

    this.primaryToast = new Toast(document.getElementById("liveToast"), {
      delay: 4000,
      autohide: true,
    });
    this.successToast = new Toast(document.getElementById("successToast"), {
      delay: 4000,
      autohide: true,
    });
    this.dangerToast = new Toast(document.getElementById("dangerToast"), {
      delay: 5000,
      autohide: true,
    });
  },
  computed: {
    /** Call state companies */
    ...mapState(["assessments"]),

    /** Find assessment by ID */
    ...mapGetters(["getAssessmentsID"]),


    currentToken() {
      return this.$store.getters.getCurrentToken;
    },

    page() {
      return this.$store.getters.getPage;
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("loadAssessmentsList");
      this.currentPage = 1;
      this.searchValue = "";
      this.searchBy = "";
      this.searchedAssessment = [];
      this.totalPages = this.$store.getters.getPage;
      this.toastMsg = "Refreshed assessment list";
      this.primaryToast.show();
    },
    findAssessment(id) {
      this.assessmentSelected = this.getAssessmentsID(id);
      // console.log(this.assessmentSelected);
    },
    viewAssessment() {
      this.$router.push(
        "/assessment/" + this.assessmentID + "/" + this.assessmentName + ""
      );
    },
    async searchAssessment() {
      // this.$store.commit("CLEAR_Members");
      const response = await axios.get(
        "admin/assessment/list",
        {
          params: {
            search_by: this.searchBy,
            search_value: this.searchValue,
            order: this.sortASC ? "ASC" : "DESC",
            limit: this.limit,
            page: this.currentPage,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        }
      );

      console.log(response.data.totalPage);
      if (response.data.totalPage === 0) {
        this.searchValue = "";
        this.searchBy = "";
        this.toastMsg = "No assessment found";
        this.dangerToast.show();
      } else {
        let newList = response.data.assessmentList;
        this.totalPages = response.data.totalPage;
        this.$store.commit("setCompanies", newList);
        this.searchedAssessment = newList;
        this.toastMsg = "Found what you're looking for!";
        this.successToast.show();
      }
    },
    viewMember(id) {
      this.$store.dispatch("loadMemberList");
      this.memberSelected = this.getMemberID(parseInt(id));
      console.log(this.memberSelected);
    },
  },
};
</script>