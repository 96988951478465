<template>
  <div>
    <!-- <p class="heading">News</p> -->
    <div class="card default-dashboard-card same-height">
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <h5>Recent news</h5>
          <a href="#"><small>read all</small></a>
        </div>
      </div>
      <div class="card-body">
        <!-- <div class="news-slider"> -->
          <div class="slide-item">
            <div
              class="img-wrapper w-100 mb-3 rounded"
              :style="
                'height: 200px; background-image: url(' + backgroundUrl + ')'
              "
            ></div>
            <div class="d-flex justify-content-between align-items-end">
              <div class="content pe-3">
                <h5 class="card-title">Special title treatment</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  ab quia beatae quas ratione eveniet id rem minus aut quos.
                </p>
              </div>
              <a href="#" class="read-link fc-primary"
                ><i class="bi bi-arrow-return-right"></i
              ></a>
            </div>
          </div>
          <!-- <div class="slide-item">
            <div
              class="img-wrapper w-100 mb-3 rounded"
              :style="
                'height: 200px; background-image: url(' + backgroundUrl + ')'
              "
            ></div>
            <div class="d-flex justify-content-between align-items-end">
              <div class="content">
                <h5 class="card-title">Special title treatment</h5>
                <p class="card-text">
                  With supporting text below as a natural lead-in to additional
                  content.
                </p>
              </div>
              <a href="#" class="read-link fc-primary"
                ><i class="bi bi-arrow-return-right"></i
              ></a>
            </div>
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import backgroundUrl from "@/assets/img/tech-img-2.jpg";
// import jQuery from "jquery";
export default {
  name: "NewsSlide",
  data() {
    return {
      backgroundUrl,
    };
  },
  mounted() {
    // jQuery(".news-slider").slick({
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: true,
    //   infinite: false,
    //   arrows: false,
    //   autoplay: true,
    // });
  },
};
</script>