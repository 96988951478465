<template>
  <div>
    <!-- <h2 class="mb-4">My companies</h2> -->
    <div class="add-company">
      <div class="row">
        <h2>My companies</h2>
      </div>
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-sm-8">
          <p class="mb-0" v-if="!companies">
            You haven't add a company yet. Add one now!
          </p>
          <p class="mb-0" v-if="companies">
            Good start! You can add more companies.
          </p>
        </div>
        <div class="col-12 col-sm-4">
          <div class="d-flex justify-content-start  justify-content-md-end mt-3 mt-md-0">
            <button
              class="btn btn-block btn-primary transition-3d-hover d-flex align-items-center justify-content-center mt-1"
              data-bs-toggle="modal"
              data-bs-target="#addNewCompany"
              @click="showForm = ''"
            >
              Add a company
            </button>
          </div>
        </div>
      </div>

      <!-- ANCHOR Company list -->

      <div class="company-list-wrapper mt-5">
        <div class="table-responsive">
          <table class="table table-hover table-borderless white-table-list">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col" class="text-center">ROC/RO</th>
                <th scope="col" class="text-center">Date Created</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody v-for="company in companies" :key="company.id">
              <tr>
                <th>{{ company.name }}</th>
                <td class="text-center">{{ company.roc_no ? company.roc_no : '-' }}</td>
                <td class="text-center">{{ company.created_at | moment }}</td>
                <td>
                  <div class="action d-flex justify-content-center">
                    <div class="action-edit text-primary">
                      <button @click="viewThisCompany(company.id, company.name)" class="btn btn-link text-primary text-center"><i class="bi bi-eye"></i></button>
                    </div>
                    <div class="action-delete">
                      <button
                        type="button"
                        class="btn btn-link text-danger text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        @click="
                          companyID = company.id;
                          companyName = company.name;
                        "
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>

    <!-- ANCHOR Modal: Delete a company -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-danger" id="deleteModalLabel">
              Delete your company
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="
                companyID = '';
                companyName = '';
              "
            ></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete {{ companyName }}?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="deleteComp(companyID)"
            >
              Delete {{ companyName }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ANCHOR Modal: Add company -->
    <div
      class="modal fade"
      id="addNewCompany"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="addNewCompanyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center px-4">
            <h5 class="modal-title mb-4" id="addNewCompanyLabel">
              Add a new company
            </h5>
            <form @submit.prevent="addCompany">
              <div class="row align-items-center">
                <div class="col-4 text-center">
                  <label for="name" class="input-label text-center"
                    >Company name</label
                  >
                </div>
                <div class="col-8">
                  <div class="input-group has-validation">
                    <input
                      type="text"
                      :class="'form-control ' + [!newCompany ? isValid : '']"
                      name="companyName"
                      id="companyName"
                      required
                      v-model="newCompany"
                    />
                    <div
                      id="registerName"
                      class="invalid-feedback"
                      v-if="errorMsg && !newCompany"
                    >
                      Please enter your company's name
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
      
  </div>
</template>

<script>

import axios from "axios";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { Modal } from "bootstrap";
import moment from "moment";

export default {
  name: "ViewCompany",
  data() {
    return {
      showForm: "d-none",
      companyID: "",
      companyName: "",
      editCompany: false,
      errorMsg: "",
      isValid: "",
      successSubmit: false,
      showProd2: "",
      showProd3: "",
      newCompany: "",
      addCompModal: null,

    };
  },
  filters: {
    /** ANCHOR Date filter */
    moment: function (date) {
      return moment(date).format("DD MMMM YYYY");
    },
  },
  computed: {
    currentToken() {
      return this.$store.getters.getCurrentToken;
    },
    ...mapState(["companies"]),
    ...mapGetters(["getCompanyID"]),
  },
  mounted() {
    this.$store.dispatch("loadCompanies");
    this.addCompModal = new Modal(document.getElementById("addNewCompany"));
  },
  methods: {
    getCompanyList() {
      this.$store.dispatch("loadCompanies")
    },
    deleteComp: async function (id) {
      console.log("Deleting company ID: " + id);
      console.log("Access token: " + this.currentToken);
      try {
        const response = await axios.post(
          "company/delete",
          {
            id: this.companyID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );
        console.log("Response: " + response);
        this.$store.dispatch("loadCompanies")
        // this.$store.commit("setCompanies", response.data.companyList);
        /** Get new company list */
      } catch (error) {
        console.log("Error: " + error);
      }
    },


    async addCompany() {
      // ANCHOR Function: Add new company

      this.addCompModal.hide();

      try {
        console.log("Created to submit...");

        const response = await axios.post(
          "company/create",
          {
            name: this.newCompany,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        );

        console.log(
          "Created a company profile: " +
            JSON.stringify(response.data.companyProfile.id)
        );
        // let id = JSON.stringify(response.data.companyProfile.id)
        // this.createdID = id
        // this.updateCompany(this.createdID)
        this.getCompanyList();
        this.newCompany = "";
      } catch (error) {
        console.log("There was an error: " + error);
      }
    },
    supertrim(string) {
            return string.replace(/\s+/g, '-').toLowerCase()
    },
    viewThisCompany(id, name) {
      let company = this.supertrim(name)
      this.$router.push('/company/'+id+'/'+company+'')
      // console.log("Company name: "+ company)
      // console.log("ID: "+ id)
    }
  },
};
</script>

    