<template>
  <div>
    <div class="d-block" v-if="errorMsg">
      <div
        class="alert position-relative alert-danger alert-dismissible fade show"
        role="alert"
      >
        <strong>Oops!</strong> {{ errorMsg }}
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          @click="dismiss()"
        ></button>
      </div>
    </div>
    <form
      class="js-validate card hero-form mx-auto"
      novalidate="novalidate"
      @submit.prevent="handleSubmit"
    >
      <div class="card-header text-center py-4 px-3 px-md-6">
        <h5 class="mb-0">Login</h5>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="emailAddress" class="input-label"
                >Email address</label
              >

              <input
                type="email"
                class="form-control"
                name="emailAddress"
                id="emailAddress"
                v-model="email"
              />
            </div>
            <!-- End Form Group -->
          </div>

          <div class="col-sm-12 mb-3">
            <!-- Form Group -->
            <div class="js-form-message form-group">
              <label for="password" class="input-label">Password</label>
              <input
                type="password"
                id="inputPassword5"
                class="form-control"
                aria-describedby="passwordHelpBlock"
                v-model="password"
              />
            </div>
            <!-- End Form Group -->
          </div>
        </div>
        <div class="text-end mb-3">
          <router-link to="/forgotPassword"
            ><small>Forgot password?</small></router-link
          >
        </div>
        <button
          type="submit"
          class="btn btn-block btn-primary transition-3d-hover d-flex align-items-center justify-content-center"
        >
          <span class="me-3">Log in</span>
          <i class="bi bi-arrow-right"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FormLogin",
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
    };
  },
  mounted(){

  },
  methods: {
    dismiss() {
      this.errorMsg = "";
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async handleSubmit() {
      try {
        // NOTE Login function
        const response = await axios.post("auth/login", {
          email: this.email,
          password: this.password,
        });
        let accessToken = response.data.access_token
        sessionStorage.setItem("cocoonx_access_token", response.data.access_token);
        this.$store.commit("setAccessToken", response.data.access_token);

        const profile = await axios.get('user/profile', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

        let role = profile.data.profile.role
        this.$store.commit("setProfile", profile.data.profile)
        console.log("Role: "+ role)

        
        this.$store.commit("setPageType", "dashboard");


        // NOTE go to Dashboard

        if(role == "ADMIN"){
          this.$router.push({ name: "Admin dashboard" });
        } else if (role == "ASSESSOR"){
          this.$router.push({ name: "Assessor dashboard" });
        }else {this.$router.push({ name: "Dashboard" });}
        
      } catch (error) {
        if (error.response) {
          let status = error.response.status;

          if (status == 500) {
            this.errorMsg = "Something went wrong. Please try again later.";
          } else {
            this.errorMsg = error.response.data.error;
          }

          this.scrollToTop()
        } else {
          console.log(error);
          alert("Enable to connect to server");
        }
        // appropriately handle the error
      }
    },
  },
};
</script>