<template>
  <div class="section cta">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-6 col-lg-6 col-lg-4">
          <div class="cta-wrapper text-center">
            <h2 class="">Reach out to us</h2>
            <p>
              We're here for you! Just give us a buzz and we'll answer your
              questions.
            </p>

            <router-link
              :to="{ name: 'Contact' }"
              class="btn btn-primary transition-3d-hover"
            >
              <span class="me-3">Contact us</span>
              <i class="bi bi-arrow-right"></i>
            </router-link>
          </div>
        </div>
        <!-- <div class="col-12 col-md-5 col-lg-6 mt-4 mt-md-0">
          <img
            src="@/assets/img/contact-us.png"
            alt="office"
            class="img-fluid"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CTA",
};
</script>